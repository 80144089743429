import React, {useEffect, useState} from "react";
import {getExchangeFromAccount} from "../../hooks/useFetchAccount";
import Datafeed from "../../utils/datafeed/datafeed";
import {widget} from "charting_library";
import {isNaN} from "@amcharts/amcharts5/.internal/core/util/Type";
import CBApi from "../../utils/datafeed/CBApi";

const TrainingViewChart = ({botType, botData, ChartHeight = '800px', BacktestActive = false}) => {
  const _DefaultTimeFrame = "1h";

  const [accountData, setAccountData] = useState([])
  const [activeChart, setActiveChart] = useState(null)
  const [changeRangeInTV, setChangeRangeInTV] = useState(null)
  const [lastTrades, setLastTrades] = useState([]);
  const [tvWidget, setTvWidget] = useState(null)
  const [chartDataLoaded, setChartDataLoaded] = useState(false)
  const [shapeIds, setShapeIds] = useState([]);

  function getBotLastTrades(botId, from = 0, to = 0) {
    const resource = `${botType}/${botId}/order/tv`
    const params = {
      // per_page: 500
    }
    if (from > 0) {
      params.from = from
    }
    if (to > 0) {
      params.to = to
    }
    return CBApi.fetch(resource, 'POST', params)
  }

  useEffect(() => {
    if (!botData) {
      return
    }
    if (!activeChart) {
      return
    }
    if (!chartDataLoaded) {
      return
    }
    // if (!activeChart.canCreateStudy) {
    //   return
    // }

    // get all past trades:
    getBotLastTrades(botData._id).then(result => setLastTrades(result));

    // Build a timer to update the setLastTrades (120 seconds):
    const interval = setInterval(() => {
      getBotLastTrades(botData._id).then(result => setLastTrades(result));
    }, 120000);

  }, [chartDataLoaded]);

  /**
   * Load all trades into the chart
   */
  useEffect(() => {
    // Old buy and sell orders:
    if (!tvWidget) {
      return
    }
    if (!activeChart) {
      return
    }
    if (!lastTrades) {
      return
    }
    // console.log(activeChart);
    // console.log(lastTrades);

    if (lastTrades?.status === 'ok') {
      // console.log('lastTrades', lastTrades.data)
      const Strategy = botData.strategy ?? 'long';

      const BuyColor = Strategy === 'long' ? '#4181BE' : '#00A59A';
      const SellColor = Strategy === 'long' ? '#00A59A' : '#4181BE';

      // Remove all shapes:

      // Move over all shapeIds Objects and remove them:
      shapeIds.forEach(id => {
        activeChart.removeEntity(id);
      });
      setShapeIds([]);

      // draw all trades:
      lastTrades.data.forEach((trade) => {
        // console.log('time', new Date(trade.exchange_lasttradedate).getTime() )
        const CurrentTimeStamp = new Date(trade.exchange_lasttradedate).getTime() / 1000 - (new Date().getTimezoneOffset() * 60);

        // check activeChart timestamp and trade timestamp
        // tvWidget.activeChart().getVisibleRange().

        // Check CurrentTimeStamp
        if (!isNaN(CurrentTimeStamp) && activeChart && activeChart.getVisibleRange().from <= CurrentTimeStamp && activeChart.getVisibleRange().to >= CurrentTimeStamp) {

          // Check, if the shape already exist:
          if (trade.ShapeId) {
            if (activeChart.getShapeById(trade.ShapeId)) {
              return
            }
          }

          const newShapeIds = [];
          if (trade.side === 'buy') {
            // Icon:
            const shapeId = activeChart.createShape({
              time: CurrentTimeStamp,
              price: trade.exchange_average,
            }, {
              // shape: 'arrow_up',
              shape: 'icon',
              icon: 62299, // 62299 - f35b - arrow_up
              lock: true,
              showInObjectsTree: false,
              disableSelection: true,
              zOrder: "top",
              // Hex Number:
              overrides: {
                color: BuyColor,
                size: 18,

                text: (trade.name ?? ''),

              }
            })
            newShapeIds.push(shapeId);

            //
            // if (EntityId) {
            //   console.log('EntityId', EntityId)
            // }
            // Text:
            const textShapeId = activeChart.createShape({
              time: CurrentTimeStamp,
              price: trade.exchange_average,
            }, {
              shape: 'note',
              text: (trade.name ?? '') +
                ' Price: ' + trade.exchange_average + '\n' +
                ' Filled: ' + trade.exchange_filled + '\n' +
                ' Date: ' + trade.exchange_lasttradedate + '\n' +
                ' Id: ' + trade.id + '\n',
              lock: true,
              showInObjectsTree: false,
              // disableSelection: true,
              zOrder: "top",
              overrides: {
                markerColor: 'rgba(0,0,0,0)', // Transparent
                backgroundColor: BuyColor,
                backgroundTransparency: 30,
                borderColor: '#000000',
                transparency: 0,
              }
            })
            newShapeIds.push(textShapeId);

          }
          if (trade.side === 'sell') {
            // Icon:
            const shapeId = activeChart.createShape({
              time: CurrentTimeStamp,
              price: trade.exchange_average,
            }, {
              // shape: 'arrow_down',
              shape: 'icon',
              icon: 62296, // 62296 - f358 - arrow_down
              lock: true,
              showInObjectsTree: false,
              zOrder: "top",
              overrides: {
                color: SellColor,
                size: 18,
                text: trade.name ?? '',
              }
            })
            newShapeIds.push(shapeId);

            // Text:
            const textShapeId = activeChart.createShape({
              time: CurrentTimeStamp,
              price: trade.exchange_average,
            }, {
              shape: 'note',
              text: (trade.name ?? '') +
                ' Price: ' + trade.exchange_average + '\n' +
                ' Filled: ' + trade.exchange_filled + '\n' +
                ' Date: ' + trade.exchange_lasttradedate + '\n' +
                ' Id: ' + trade.id + '\n',
              lock: true,
              showInObjectsTree: false,
              // disableSelection: true,
              zOrder: "top",
              overrides: {
                markerColor: 'rgba(0,0,0,0)', // Transparent
                backgroundColor: SellColor,
                backgroundTransparency: 30,
                borderColor: '#000000',
                transparency: 0,
              }
            })
            newShapeIds.push(textShapeId);
          }
          setShapeIds(prevShapeIds => [...prevShapeIds, ...newShapeIds]);
        }
      })
    }
  }, [lastTrades, activeChart, changeRangeInTV])


  const getTvChart = () => {
    if (!accountData || accountData.length === 0 || !botData || !botData.account_id) {
      return null
    }
    const exchange = getExchangeFromAccount(accountData, botData.account_id, 'type')
    let symbol = `${exchange}:${botData.symbol}`

    // get First Element of accountData for userId
    const userId = accountData.user_id || 'none'

    const widgetOptions = {
      debug: false,
      autosize: true,
      // datafeed: '${Datafeed}',
      datafeed: Datafeed,
      charts_storage_api_version: "1.1",
      charts_storage_url: "https://saveload.tradingview.com",
      client_id: "tradingview.com",
      container: "tv_chart_container",
      disabled_features: [
        "use_localstorage_for_settings",
        "header_symbol_search",
        "symbol_search_hot_key",
        "header_compare",
      ],
      enabled_features: [
        "fix_left_edge",
        "studies_extend_time_scale",
      ],
      fullscreen: false,
      // interval: "5",
      interval: _DefaultTimeFrame,
      // interval: "D",

      // interval: "1h",
      // library_path: "../../charting_library/",
      library_path: "/charting_library/",
      locale: "en",
      studies_overrides: undefined,
      symbol: symbol,
      theme: "Dark",
      timezone: "Etc/UTC",
      user_id: "xyv-cb-" + userId,
      // overrides: {
      //   "mainSeriesProperties.style": 8,
      // },
      custom_indicators_getter: function (PineJS) {
        return Promise.resolve([
          {
            name: "CB Relative Strength Index",
            metainfo: {
              _metainfoVersion: 52,
              defaults: {
                styles: {
                  plot_0: {
                    linestyle: 0,
                    linewidth: 1,
                    plottype: 0,
                    trackPrice: !1,
                    transparency: 0,
                    visible: !0,
                    color: "#7E57C2"
                  },
                  smoothedMA: {
                    linestyle: 0,
                    linewidth: 1,
                    plottype: 0,
                    trackPrice: !1,
                    transparency: 0,
                    visible: !1
                  }
                },
                bands: [{
                  color: "#787B86",
                  linestyle: 2,
                  linewidth: 1,
                  visible: true,
                  value: 70
                }, {
                  color: "#787B86",
                  linestyle: 2,
                  linewidth: 1,
                  visible: false,
                  value: 30
                }],
                filledAreasStyle: {
                  fill_0: {
                    color: "#7E57C2",
                    transparency: 90,
                    visible: false
                  }
                },
                inputs: {
                  TimeFrame: _DefaultTimeFrame,
                  length: 14,
                  smoothingLine: "SMA",
                  smoothingLength: 14,
                }
              },
              plots: [{
                id: "plot_0",
                type: "line"
              }, {
                id: "smoothedMA",
                type: "line"
              }],
              styles: {
                plot_0: {
                  title: "Plot",
                  histogramBase: 0,
                  joinPoints: !1
                },
                smoothedMA: {
                  title: "Smoothed MA",
                  histogramBase: 0,
                  joinPoints: !1
                }
              },
              bands: [{
                id: "hline_0",
                name: "UpperLimit"
              }, {
                id: "hline_1",
                name: "LowerLimit"
              }],
              filledAreas: [{
                id: "fill_0",
                objAId: "hline_0",
                objBId: "hline_1",
                type: "hline_hline",
                title: "Hlines Background"
              }],
              inputs: [{
                id: "TimeFrame",
                name: "TimeFrame",
                defval: _DefaultTimeFrame,
                type: "resolution",
              }, {
                id: "length",
                name: "Length",
                defval: 14,
                type: "integer",
                min: 1,
                max: 2e3
              }, {
                id: "smoothingLine",
                name: "Smoothing Line",
                defval: "SMA",
                type: "text",
                options: ["SMA", "EMA", "WMA"]
              }, {
                id: "smoothingLength",
                name: "Smoothing Length",
                defval: 14,
                type: "integer",
                min: 1,
                max: 1e4
              }],
              id: "CB Relative Strength Index@tv-basicstudies-1",
              scriptIdPart: "",
              name: " CB Relative Strength Index",
              description: " CB Relative Strength Index",
              shortDescription: "RSI",
              is_price_study: false,
              isTVScript: !1,
              isTVScriptStub: !1,
              is_hidden_study: !1,
              canExtendTimeScale: true,
              format: {
                precision: 2,
                type: "price"
              },


            },
            constructor: function () {
              this.f_0 = function (e) {
                return PineJS.Std.max(e, 0)
              }
              this.f_1 = function (e) {
                return -PineJS.Std.min(e, 0)
              }
              this.f_2 = function (e, t) {
                return PineJS.Std.eq(e, 0) ? 100 : PineJS.Std.eq(t, 0) ? 0 : 100 - 100 / (1 + t / e)
              }
              this.init = function (context, inputCallback) {
                this._context = context
                this._input = inputCallback

                // Change TimeFrame
                this._timeframe = this._input(0)

                if (this._timeframe && this._context.symbol.interval !== this._timeframe) {
                  this._context.new_sym(this._context.symbol.tickerid, this._timeframe)
                }

              }
              this.main = function (context, inputCallback) {
                this._context = context
                this._input = inputCallback;

                // Change TimeFrame
                if (this._timeframe && this._context.symbol.interval !== this._timeframe) {
                  this._context.select_sym(1)
                }

                var i = PineJS.Std.close(this._context)
                  , s = this._input(1)
                  , n = this._input(2)
                  , o = this._input(3);

                // this._context.setMinimumAdditionalDepth(s + o);
                var a, l = this._context.new_var(i), c = PineJS.Std.change(l), h = this.f_0(c),
                  d = this._context.new_var(h), u = PineJS.Std.rma(d, s, this._context), p = this.f_1(c),
                  _ = this._context.new_var(p), m = PineJS.Std.rma(_, s, this._context), g = this.f_2(m, u),
                  f = this._context.new_var(g);
                return "EMA" === n ? a = PineJS.Std.ema(f, o, this._context) : "WMA" === n ? a = PineJS.Std.wma(f, o, this._context) : "SMA" === n && (a = PineJS.Std.sma(f, o, this._context)),
                  [{
                    value: g
                  }, {
                    value: a
                  }]
              }
            }
          },
          {
            name: "CB Average True Range",
            metainfo: {
              _metainfoVersion: 52,
              isTVScript: !1,
              isTVScriptStub: !1,
              is_hidden_study: !1,

              isMTFGaps: 1,
              isMTFResolution: 1,

              description: " CB Average True Range",
              shortDescription: "ATR",
              is_price_study: !1,
              canExtendTimeScale: true,

              id: " CB Average True Range@tv-basicstudies-1",
              scriptIdPart: "",
              name: " CB Average True Range",
              format: {
                type: "inherit"
              },

              defaults: {
                styles: {
                  plot_0: {
                    linestyle: 0,
                    linewidth: 1,
                    plottype: 0,
                    trackPrice: !1,
                    transparency: 0,
                    visible: !0,
                    color: "#7E57C2"
                  }
                },
                inputs: {
                  length: 14,
                  // smoothing: "SMA",
                  // multiplier: 1,
                },
                bands: [{
                  color: "#787B86",
                  linestyle: 2,
                  linewidth: 1,
                  visible: false,
                  value: 70
                }, {
                  color: "#787B86",
                  linestyle: 2,
                  linewidth: 1,
                  visible: false,
                  value: 30
                }],
                filledAreasStyle: {
                  fill_0: {
                    color: "#7E57C2",
                    transparency: 90,
                    visible: false
                  }
                }
              },
              plots: [{
                id: "plot_0",
                type: "line"
              }],
              styles: {
                plot_0: {
                  title: "Plot",
                  histogramBase: 0,
                  joinPoints: !1
                }
              },
              bands: [{
                id: "hline_0",
                name: "UpperLimit"
              }, {
                id: "hline_1",
                name: "LowerLimit"
              }],
              filledAreas: [{
                id: "fill_0",
                objAId: "hline_0",
                objBId: "hline_1",
                type: "hline_hline",
                title: "Hlines Background"
              }],
              inputs: [{
                id: "TimeFrame",
                name: "TimeFrame",
                defval: _DefaultTimeFrame,
                type: "resolution",
              }, {
                id: "length",
                name: "length",
                defval: 14,
                type: "integer",
                min: 1,
                max: 2e3
                // }, {
                //   id: "smoothing",
                //   name: "Smoothing",
                //   defval: "SMA",
                //   type: "text",
                //   options: ["SMA", "EMA", "WMA", "RMA"]
                // }, {
                //   id: "multiplier",
                //   name: "multiplier",
                //   defval: 1,
                //   type: "float",
                //   min: 1,
                //   max: 2e3
              }],
            },
            constructor: function () {
              this.init = function (context, inputCallback) {
                this._context = context
                this._input = inputCallback

                // // Change TimeFrame
                this._timeframe = this._input(0)

                if (this._timeframe && this._context.symbol.interval !== this._timeframe) {
                  this._context.new_sym(this._context.symbol.tickerid, this._timeframe)
                }

              }
              this.main = function (context, inputCallback) {
                this._context = context
                this._input = inputCallback;

                // Change TimeFrame
                if (this._timeframe && this._context.symbol.interval !== this._timeframe) {
                  this._context.select_sym(1)
                }

                // var o = this._input(1)
                //   // , smoothing = this._input(2)
                //   // , multiplier = this._input(3)
                //   , s = PineJS.Std.atr(o, this._context);
                //   // , s = PineJS.Std.tr(o, context);
                // // console.log("o", o);
                // // console.log("s", s);
                // // console.log("this._context", this._context);
                //
                // // Smoothing
                // // var r = "EMA" === smoothing ? PineJS.Std.ema(s, o, context) : "WMA" === smoothing ? PineJS.Std.wma(s, o, context) : "RMA" === smoothing ? PineJS.Std.rma(s, o, context) : PineJS.Std.sma(s, o, context);
                // // return [s * multiplier]
                // // return [r * multiplier]
                // return [s]

                var o = this._input(1)
                  // , r = this._input(2)
                  , s = PineJS.Std.atr(o, this._context);
                // return e && e(s, r),
                //   [s]
                return [s]
              }
            }
          },
          {
            name: "CB EMA Cross",
            metainfo: {
              _metainfoVersion: 52,
              isTVScript: !1,
              isTVScriptStub: !1,
              is_hidden_study: !1,

              isMTFGaps: 1,
              isMTFResolution: 1,

              description: " CB EMA Cross",
              shortDescription: "EMA Cross",
              is_price_study: true,
              canExtendTimeScale: true,

              id: " CB EMA Cross@tv-basicstudies-1",
              scriptIdPart: "",
              name: " CB EMA Cross",
              format: {
                type: "inherit"
              },

              defaults: {
                styles: {
                  plot_0: {
                    linestyle: 0,
                    linewidth: 1,
                    plottype: 0,
                    trackPrice: false,
                    transparency: 0,
                    visible: true,
                    color: "#FF6D00"
                  },
                  plot_1: {
                    linestyle: 0,
                    linewidth: 1,
                    plottype: 0,
                    trackPrice: false,
                    transparency: 0,
                    visible: true,
                    color: "#43A047"
                  },
                  plot_2: {
                    linestyle: 0,
                    linewidth: 4,
                    plottype: 3,
                    trackPrice: !1,
                    transparency: 0,
                    visible: true,
                    color: "#2196F3"
                  }
                },
                inputs: {
                  TimeFrame: "1h",
                  length1: 7,
                  length2: 28
                },
                bands: [{
                  color: "#787B86",
                  linestyle: 2,
                  linewidth: 1,
                  visible: false,
                  value: 70
                }, {
                  color: "#787B86",
                  linestyle: 2,
                  linewidth: 1,
                  visible: false,
                  value: 30
                }],
                filledAreasStyle: {
                  fill_0: {
                    color: "#7E57C2",
                    transparency: 90,
                    visible: false
                  }
                }
              },
              plots: [
                {
                  id: "plot_0",
                  type: "line"
                },
                {
                  id: "plot_1",
                  type: "line"
                },
                {
                  id: "plot_2",
                  type: "line"
                },
              ],
              styles: {
                plot_0: {
                  title: "Plot",
                  histogramBase: 0,
                  joinPoints: !1
                },
                plot_1: {
                  title: "Plot",
                  histogramBase: 0,
                  joinPoints: !1
                },
                plot_2: {
                  title: "Crosses",
                  histogramBase: 0,
                  joinPoints: !1
                },
              },
              bands: [{
                id: "hline_0",
                name: "UpperLimit"
              }, {
                id: "hline_1",
                name: "LowerLimit"
              }],
              filledAreas: [{
                id: "fill_0",
                objAId: "hline_0",
                objBId: "hline_1",
                type: "hline_hline",
                title: "Hlines Background"
              }],
              inputs: [
                {
                  id: "TimeFrame",
                  name: "TimeFrame",
                  defval: "1m",
                  type: "resolution"
                },
                {
                  id: "length1",
                  name: "EMA Length 1",
                  defval: 7,
                  type: "integer",
                  min: 1,
                  max: 2000
                },
                {
                  id: "length2",
                  name: "EMA Length 2",
                  defval: 28,
                  type: "integer",
                  min: 1,
                  max: 2000
                }
              ]
            },
            constructor: function () {
              this.init = function (context, inputCallback) {
                this._context = context
                this._input = inputCallback

                // // Change TimeFrame
                this._timeframe = this._input(0)

                if (this._timeframe && this._context.symbol.interval !== this._timeframe) {
                  this._context.new_sym(this._context.symbol.tickerid, this._timeframe)
                }

              }

              this.f_0 = function (e, t) {
                return e ? t : PineJS.Std.na()
              }

              this.main = function (context, inputCallback) {
                this._context = context;
                this._input = inputCallback;

                if (this._timeframe && this._context.symbol.interval !== this._timeframe) {
                  this._context.select_sym(1);
                }

                var close = PineJS.Std.close(this._context);
                var closeSeries = this._context.new_var(close);
                var length1 = this._input(1);
                var length2 = this._input(2);

                var ema1 = PineJS.Std.ema(closeSeries, length1, this._context);
                var ema2 = PineJS.Std.ema(closeSeries, length2, this._context);

                var cross = PineJS.Std.cross(ema1, ema2, this._context);

                return [
                  ema1, ema2, this.f_0(cross, ema1)
                ]
              }
            }
          },
          // Parabolic SAR
          {
            name: "CB Parabolic SAR",
            metainfo: {
              _metainfoVersion: 52,
              isTVScript: !1,
              isTVScriptStub: !1,
              is_hidden_study: !1,

              // isMTFGaps: 1,
              // isMTFResolution: 1,

              description: " CB Parabolic SAR",
              shortDescription: "Parabolic SAR",
              is_price_study: !0,
              canExtendTimeScale: true,

              id: " CB Parabolic SAR@tv-basicstudies-1",
              scriptIdPart: "",
              name: " CB Parabolic SAR",
              format: {
                type: "inherit"
              },
              defaults: {
                styles: {
                  plot_0: {
                    linestyle: 0,
                    linewidth: 1,
                    plottype: 3,
                    trackPrice: !1,
                    transparency: 0,
                    visible: !0,
                    color: "#2196F3"
                  }
                },
                inputs: {
                  TimeFrame: "1h",
                  in_0: .02,
                  in_1: .02,
                  in_2: .2
                }
              },
              plots: [{
                id: "plot_0",
                type: "line"
              }],
              styles: {
                plot_0: {
                  title: "Plot",
                  histogramBase: 0,
                  joinPoints: !1
                }
              },
              inputs: [{
                id: "TimeFrame",
                name: "TimeFrame",
                defval: "1m",
                type: "resolution"
              }, {
                id: "in_0",
                name: "start",
                defval: .02,
                type: "float",
                min: -1e12,
                max: 1e12
              }, {
                id: "in_1",
                name: "increment",
                defval: .02,
                type: "float",
                min: -1e12,
                max: 1e12
              }, {
                id: "in_2",
                name: "maximum",
                defval: .2,
                type: "float",
                min: -1e12,
                max: 1e12
              }],
            },
            constructor: function () {
              this.init = function (context, inputCallback) {
                this._context = context
                this._input = inputCallback

                // // Change TimeFrame
                this._timeframe = this._input(0)

                if (this._timeframe && this._context.symbol.interval !== this._timeframe) {
                  this._context.new_sym(this._context.symbol.tickerid, this._timeframe)
                }

              }

              this.main = function (context, inputCallback) {
                this._context = context;
                this._input = inputCallback;

                if (this._timeframe && this._context.symbol.interval !== this._timeframe) {
                  this._context.select_sym(1);
                }

                var i = this._input(1)
                  , s = this._input(2)
                  , n = this._input(3);
                var sar = PineJS.Std.sar(i, s, n, this._context)
                return [sar]
              }
            }
          },
          {
            name: "CB Price Change",
            metainfo: {
              _metainfoVersion: 52,
              isTVScript: !1,
              isTVScriptStub: !1,
              is_hidden_study: !1,

              isMTFGaps: 1,
              isMTFResolution: 1,

              description: " CB Price Change",
              shortDescription: "Price Change",
              is_price_study: true,
              canExtendTimeScale: true,

              id: " CB Price Change@tv-basicstudies-1",
              scriptIdPart: "",
              name: " CB Price Change",
              format: {
                type: "inherit"
              },

              defaults: {
                styles: {
                  up: {
                    plottype: "shape_triangle_up",
                    location: "BelowBar",
                    visible: !0,
                    color: "#4caf4f",
                    textColor: "#4caf4f",
                    transparency: 0,
                    fontsize: 30
                  },
                  down: {
                    plottype: "shape_triangle_down",
                    location: "AboveBar",
                    visible: !0,
                    color: "#ff5252",
                    textColor: "#ff5252",
                    transparency: 0,
                    fontsize: 30
                  },
                  bgColor: {
                    visible: !0,
                    transparency: 90
                  }
                },
                palettes: {
                  paletteBg: {
                    colors: {
                      1: {
                        color: "#4caf4f"
                      },
                      2: {
                        color: "#ff5252"
                      }
                    }
                  }
                },
                inputs: {
                  TimeFrame: "1h",
                  up: 5,
                  down: 5,
                  showUp: !0,
                  showDown: !1
                },
              },
              plots: [{
                id: "up",
                type: "shapes",
                plottype: "shape_triangle_up",
                location: "BelowBar"
              }, {
                id: "down",
                type: "shapes",
                plottype: "shape_triangle_down",
                location: "AboveBar"
              }, {
                id: "bgColor",
                type: "bg_colorer",
                palette: "paletteBg"
              }],
              styles: {
                up: {
                  title: "Price increased"
                },
                down: {
                  title: "Price decreased"
                },
                bgColor: {
                  title: "Background color"
                }
              },
              palettes: {
                paletteBg: {
                  colors: {
                    1: {
                      name: "Up"
                    },
                    2: {
                      name: "Down"
                    }
                  },
                  valToIndex: {
                    1: 1,
                    2: 2
                  }
                }
              },
              inputs: [
                {
                  id: "TimeFrame",
                  name: "TimeFrame",
                  defval: "1m",
                  type: "resolution"
                },
                {
                  id: "up",
                  name: "% up",
                  defval: 5,
                  type: "float",
                  min: .1,
                  max: 1e3,
                  step: .1
                }, {
                  id: "down",
                  name: "% down",
                  defval: 5,
                  type: "float",
                  min: .1,
                  max: 1e3,
                  step: .1
                }, {
                  id: "showUp",
                  name: "Show Price Increased",
                  defval: !0,
                  type: "bool"
                }, {
                  id: "showDown",
                  name: "Show Price Decreased",
                  defval: !0,
                  type: "bool"
                }, {
                  id: "sumcandles",
                  name: "Sum Candles",
                  defval: 1,
                  type: "integer",
                }
              ]
            },
            constructor: function () {
              this.init = function (context, inputCallback) {
                this._context = context
                this._input = inputCallback

                // // Change TimeFrame
                this._timeframe = this._input(0)

                if (this._timeframe && this._context.symbol.interval !== this._timeframe) {
                  this._context.new_sym(this._context.symbol.tickerid, this._timeframe)
                }

              }

              this.main = function (context, inputCallback) {
                this._context = context;
                this._input = inputCallback;

                if (this._timeframe && this._context.symbol.interval !== this._timeframe) {
                  this._context.select_sym(1);
                }

                // var sumcandles = this._input(5) || 1
                // var hi = PineJS.Std.high(this._context)
                // var lo = PineJS.Std.low(this._context)
                // var hv = this._context.new_var(hi)
                // var lv = this._context.new_var(lo)


                // var high = PineJS.Std.highestbars(hv, sumcandles, this._context)
                //   , low = PineJS.Std.lowestbars(lv, sumcandles, this._context)
                var high = PineJS.Std.high(this._context)
                  , low = PineJS.Std.low(this._context)
                  , open = PineJS.Std.open(this._context)
                  , percentUp = this._input(1)
                  , percentDown = this._input(2)
                  , priceIncreased = this._input(3)
                  , priceDecreased = this._input(4)
                  , c = 100 * Math.abs((high - open) / open)
                  , h = 100 * Math.abs((low - open) / open)
                  , u = priceIncreased && c > percentUp ? 1 : 0
                  , p = priceDecreased && h > percentDown ? 1 : 0;
                return [u, p, u ? 1 : p ? 2 : 0]
              }
            }
          },
        ]);
      }
    };

    const tvWidget = new widget(widgetOptions);
    setTvWidget(tvWidget)
    // window.tvWidget = tvWidget;
    tvWidget.onChartReady(() => {

      tvWidget.activeChart().onVisibleRangeChanged().subscribe(null, function (range) {
        setChangeRangeInTV(range)
      })

      tvWidget.activeChart().onSymbolChanged().subscribe(null, function (symbol) {
        setChangeRangeInTV(null)
      })

      tvWidget.activeChart().onIntervalChanged().subscribe(null, function (interval) {
        setChangeRangeInTV(null)
      })

      // // Fetch TradingView Lines from the api and ask a function:
      // const apiHost = process.env.REACT_APP_API_URI
      // const resource = `${apiHost}/gridbot/${botData._id}/cryptowidget`
      // const fetchOptions = {
      //   headers: {
      //     Authorization: `Bearer ${tokenFromLS(globalState.token)}`,
      //   },
      // }
      // const fetchData = async () => {
      //   try {
      //     const response = await fetch(resource, fetchOptions)
      //     return await response.json()
      //   } catch (error) {
      //     console.log('error', error)
      //     return null
      //   }
      // }
      // fetchData().then((data) => {
      //   // console.log('fetchData', data)
      //   const _current_strategy = data && data.current_strategy ? data.current_strategy : 'long'
      //   const _current_price = data && data.current_price ? data.current_price : 0.0
      //   const _current_price_average = data && data.current_price_average ? data.current_price_average : 0.0
      //   const _current_unrealized_profit = data && data.current_unrealized_profit ? _current_strategy === 'long' ? data.current_unrealized_profit : -data.current_unrealized_profit : 0.0
      //   const _current_amount_total = data && data.current_amount_total ? _current_strategy === 'long' ? data.current_amount_total : -data.current_amount_total : "0"
      //   const _current_trailing_price = data && data.current_trailing_price ? data.current_trailing_price : 0.0
      //   let _current_price_color = 'rgb(237, 85, 101)'
      //   if (_current_price > _current_price_average) {
      //     _current_price_color = 'rgb(26, 179, 148)'
      //   }
      //
      //   if (!tvWidget) {
      //     return
      //   }
      //   const ActiveChart = tvWidget.activeChart() || null
      //
      //   if (!ActiveChart) {
      //     return
      //   }
      //
      //   tvWidget.activeChart().onVisibleRangeChanged().subscribe(null, function (range) {
      //     // console.log('range', range)
      //     // console.log('range', range.from, range.to)
      //     // get all past trades:
      //     // getBotLastTrades(botData._id, range.from, range.to).then(result => setLastTrades(result));
      //     setChangeRangeInTV(range)
      //   })
      //
      //   tvWidget.activeChart().onSymbolChanged().subscribe(null, function (symbol) {
      //     setChangeRangeInTV(null)
      //     getBotLastTrades(botData._id).then(result => setLastTrades(result));
      //   })
      //
      //   tvWidget.activeChart().onIntervalChanged().subscribe(null, function (interval) {
      //     setChangeRangeInTV(null)
      //     getBotLastTrades(botData._id).then(result => setLastTrades(result));
      //   })
      //
      //   // Current price line:
      //   if (ActiveChart && _current_price_average !== 0.0) {
      //     ActiveChart.createPositionLine()
      //       .setTooltip('Current average price')
      //       .setText('Average | ' + _current_unrealized_profit + " " + getSymbolQuote(botData.symbol))
      //       .setPrice(_current_price_average)
      //       // .setQuantity(1)
      //       .setLineColor(_current_price_color)
      //       // Line style: 0 - solid, 1 - dashed, 2 - dotted, 3 - large dashed, 4 - large dotted
      //       .setLineStyle(4)
      //       .setQuantity(_current_amount_total ?? "0")
      //   }
      //
      //   // Trailing SO:
      //   if (ActiveChart && _current_trailing_price > 0.0) {
      //     ActiveChart.createOrderLine()
      //       .setTooltip("Trailing Order\n"
      //         + "Name: " + data.current_trailing_name + "\n" +
      //         +"Price: " + _current_trailing_price + "\n")
      //       .setText('Trailing SO | ' + _current_trailing_price + " " + getSymbolQuote(botData.symbol))
      //       .setPrice(_current_trailing_price)
      //       .setLineColor("#FFB0B1")
      //       .setLineStyle(4)
      //
      //   }
      //
      //   // Current buy and sell lines:
      //   for (let i = 0; i < Object.keys(data ?? []).length - 1; i++) {
      //     if (isObject(data[i]) && data[i].id) {
      //       let item = data[i]
      //       // data.forEach((item) => {
      //       // console.log('item', item)
      //
      //       // is Object:
      //       if (isObject(item) && (item.state === 'created' || item.state === 'waiting')) {
      //         // "0": {
      //         //   "id": 1589,
      //         //     "side": "sell",
      //         //     "state": "created",
      //         //     "type": "limit",
      //         //     "amount": 13.051,
      //         //     "price": 18.3467,
      //         //     "name": "bo_tp_0",
      //         //     "exchange_createdate": "2023-11-06 23:00:30",
      //         //     "exchange_filled": 0,
      //         //     "exchange_lasttradedate": "2023-11-06 23:00:30",
      //         //     "exchange_remaining": 13.051
      //         // },
      //         if (item.side === 'sell') {
      //           tvWidget.activeChart().createOrderLine()
      //             // .setTooltip(item.tooltip)
      //             // .setModifyTooltip(item.modifytooltip)
      //             // .setCancelTooltip(item.canceltooltip)
      //             // .onMove(function () {
      //             //   console.log('Order line moved')
      //             // })
      //             // .onModify(function () {
      //             //   console.log('Order line modified')
      //             // })
      //             .onCancel(function () {
      //               sendOrder('cancel', item.id)
      //             })
      //             .setText(item.name)
      //             .setTooltip("Side: " + item.side + "\n" + "Amount: " + item.amount + "\n" + "Price: " + item.price + "\n" + "Created: " + item.exchange_createdate)
      //             .setQuantity(item.amount)
      //             .setPrice(item.price)
      //             .setLineColor("#00A59A")
      //             .setLineStyle(4)
      //             .setQuantityTextColor("#9aedeb")
      //             .setBodyBackgroundColor("#04605a")
      //             .setBodyTextColor("#67fdf5")
      //             .setQuantityBackgroundColor("#078078")
      //             .setQuantityTextColor("#02eadc")
      //             .setCancelButtonBackgroundColor("#04605a")
      //             .setCancelButtonIconColor("#02eadc")
      //             .setBodyBorderColor("#10d6c8")
      //             .setQuantityBorderColor("#10d6c8")
      //             .setCancelButtonBorderColor("#10d6c8")
      //         }
      //         if (item.side === 'buy') {
      //           tvWidget.activeChart().createOrderLine()
      //             // .setTooltip(item.tooltip)
      //             .onCancel(function () {
      //               sendOrder('cancel', item.id)
      //             })
      //             .setText(item.name)
      //             .setTooltip("Side: " + item.side + "\n" + "Amount: " + item.amount + "\n" + "Price: " + item.price + "\n" + "Created: " + item.exchange_createdate)
      //             .setQuantity(item.amount)
      //             .setPrice(item.price)
      //             .setLineColor("#4181BE")
      //             .setLineStyle(4)
      //             .setQuantityTextColor("#9ab4ed")
      //             .setBodyBackgroundColor("#0a3b6b")
      //             .setBodyTextColor("#6e8fd6")
      //             .setQuantityBackgroundColor("#0a3b6b")
      //             .setQuantityTextColor("#6e8fd6")
      //             .setCancelButtonBackgroundColor("#0a3b6b")
      //             .setCancelButtonIconColor("#6e8fd6")
      //             .setBodyBorderColor("#6e8fd6")
      //             .setQuantityBorderColor("#6e8fd6")
      //             .setCancelButtonBorderColor("#6e8fd6")
      //
      //
      //         }
      //       }
      //       // if (isObject(item) && item.state === 'completed') {
      //       //   // "1": {
      //       //   //   "id": 1590,
      //       //   //     "side": "buy",
      //       //   //     "state": "filled",
      //       //   //     "type": "limit",
      //       //   //     "amount": 13.051,
      //       //   //     "price": 18.3467,
      //       //   //     "name": "bo_tp_0",
      //       //   //     "exchange_createdate": "2023-11-06 23:00:30",
      //       //   //     "exchange_filled": 13.051,
      //       //   //     "exchange_lasttradedate": "2023-11-06 23:00:30",
      //       //   //     "exchange_remaining": 0
      //       //   // },
      //       //   if (item.side === 'sell') {
      //       //     tvWidget.activeChart().createOrderLine()
      //       //       .setTooltip(item.tooltip)
      //       //       .setText(item.name)
      //       //       .setTooltip("Side: " + item.side + "\n" + "Amount: " + item.amount + "\n" + "Price: " + item.price + "\n" + "Created: " + item.exchange_createdate)
      //       //       .setQuantity(item.amount)
      //       //       .setPrice(item.price)
      //       //       .setLineColor("#00A59A")
      //       //   }
      //       //   if (item.side === 'buy') {
      //       //     tvWidget.activeChart().createOrderLine()
      //       //       .setTooltip(item.tooltip)
      //       //       .setText(item.name)
      //       //       .setTooltip("Side: " + item.side + "\n" + "Amount: " + item.amount + "\n" + "Price: " + item.price + "\n" + "Created: " + item.exchange_createdate)
      //       //       .setQuantity(item.amount)
      //       //       .setPrice(item.price)
      //       //       .setLineColor("#4181BE")
      //       //   }
      //       // }
      //     }
      //   }
      // })

      setActiveChart(tvWidget.activeChart())

      // tvWidget.activeChart().createOrderLine()
      //   .setTooltip('My order')
      //   // .setModifyTooltip('Modify my order')
      //   .setCancelTooltip('Cancel my order')
      //   .onMove(function () {
      //     console.log('Order line moved')
      //     return false
      //   })
      //   // .onModify(function () {
      //   //   console.log('Order line modified')
      //   // })
      //   .onCancel(function () {
      //     console.log('Order line canceled')
      //   })
      //   .setText('Text on Order')
      //   .setQuantity("2");
      // tvWidget.activeChart().createPositionLine()
      //   .setTooltip('My position')
      //   .setText('Text on Position')
      //   // .setPrice()
      //    .setQuantity("23");
      //
      // 47860: (e,t,n)=>{
      //   n.d(t, {
      //     g: ()=>i,
      //     i: ()=>f
      //   });
      //   var r = n(28663)
      //     , o = n(79872)
      //     , a = {
      //     Dark: {
      //       "body-background-color": "rgba(24, 33, 41, 0.6)",
      //       "quantity-background-color": "rgba(255, 255, 255, 0.75)",
      //       "quantity-text-color": "rgb(255, 255, 255)",
      //       "sl-quantity-background-color": "#EE7D8B",
      //       "tp-quantity-background-color": "#00A59A",
      //       "buy-quantity-background-color": "#1E72D2",
      //       "sl-line-color": "#DFAD70",
      //       "tp-line-color": "#50B095",
      //       "buy-line-color": "#4181BE",
      //       "pending-line-color": "#929da8"
      //     },
      //     Light: {
      //       "body-background-color": "rgba(255, 255, 255, 0.75)",
      //       "quantity-background-color": "rgba(255, 255, 255, 0.75)",
      //       "quantity-text-color": "rgb(255, 255, 255)",
      //       "sl-quantity-background-color": "#EE7D8B",
      //       "tp-quantity-background-color": "#00A59A",
      //       "sl-line-color": "#E8AC65",
      //       "tp-line-color": "#50B095",
      //       "buy-line-color": "#4181BE",
      //       "pending-line-color": "#929da8"
      //     }
      //   }
      // , u = function(e, t) {
      //     return "BUY" === e ? "Light" === t ? "#06634f" : "#00ffd1" : "Light" === t ? "#870101" : "#FFB0B1"

      // , s = function(e, t) {
      //     var n = arguments.length > 2 && void 0 !== arguments[2] ? arguments[2] : "Light"
      //       , r = (null == t ? void 0 : t.iconColor) || u(t.side, n)
      //       , o = (null == t ? void 0 : t.iconSize) || 15
      //       , a = (null == t ? void 0 : t.icon) || ("BUY" === t.side ? 61656 : 61655);
      //     return {
      //       type: "mark",
      //       value: e.createShape({
      //         time: t.execution_time,
      //         price: Number(t.price)
      //       }, {
      //         shape: "icon",
      //         icon: a,
      //         zOrder: "top",
      //         overrides: {
      //           size: o,
      //           color: r
      //         }
      //       })
      //     }
      //   }
      // // , l = function(e, t) {
      //     var n = arguments.length > 2 && void 0 !== arguments[2] ? arguments[2] : "Light"
      //       , o = e.createOrderLine()
      //       , a = t.options
      //       , i = void 0 === a ? {} : a
      //       , u = i.lineHeight
      //       , s = i.bodyBorderColor
      //       , l = i.bodyTextColor
      //       , f = i.lineColor
      //       , b = i.quantityBorderColor
      //       , p = i.quantityTextColor
      //       , d = i.quantityBackgroundColor
      //       , y = i.cancelButtonBorderColor
      //       , m = i.cancelButtonIconColor
      //       , v = i.cancelButtonBackgroundColor
      //       , h = i.bodyBackgroundColor
      //       , g = i.lineStyle
      //       , w = i.hidePriceLabel;
      //     t.editable && (o.onMove(function() {
      //       t.onMove(this.getPrice(), o)
      //     }).onModify("onModify called", function() {}),
      //     t.onCancel && o.onCancel(t.onCancel)),
      //     void 0 !== w && w && (o._line.customization.forcePriceAxisLabel = !1);
      //     var A = c(n);
      //     return o.setPrice(t.price).setText(t.text).setQuantity(t.quantity).setLineStyle(g || 0).setQuantityBackgroundColor(d || A("quantity-background-color")).setQuantityTextColor(p || A("quantity-text-color")).setBodyBackgroundColor(h || A("body-background-color")),
      //       t.type === r.U_.Sl ? o.setLineLength(u || 7).setBodyBorderColor(s || A("sl-line-color")).setBodyTextColor(l || A("sl-line-color")).setLineColor(f || A("sl-line-color")).setQuantityBackgroundColor(A("sl-quantity-background-color")).setQuantityBorderColor(A("sl-quantity-background-color")).setCancelButtonBorderColor(y || A("sl-line-color")).setCancelButtonBackgroundColor(v || A("body-background-color")).setCancelButtonIconColor(m || A("sl-line-color")) : t.type === r.U_.Tp || t.type === r.U_.Mp ? o.setLineLength(7).setBodyBorderColor(s || A("tp-line-color")).setBodyTextColor(l || A("tp-line-color")).setLineColor(f || A("tp-line-color")).setQuantityBackgroundColor(A("tp-quantity-background-color")).setQuantityBorderColor(A("tp-quantity-background-color")).setCancelButtonBorderColor(y || A("tp-line-color")).setCancelButtonBackgroundColor(v || A("body-background-color")).setCancelButtonIconColor(m || A("tp-line-color")) : t.type === r.U_.Buy ? o.setLineLength(u || 7).setBodyBorderColor(b || A("buy-line-color")).setBodyTextColor(l || A("buy-line-color")).setLineColor(f || A("buy-line-color")).setQuantityBackgroundColor(A("buy-quantity-background-color")).setQuantityBorderColor(A("buy-quantity-background-color")).setCancelButtonBorderColor(y || A("buy-line-color")).setCancelButtonBackgroundColor(v || A("body-background-color")).setCancelButtonIconColor(m || A("buy-line-color")) : t.type === r.U_.Pnd && o.setLineLength(u || 7).setBodyBorderColor(s || A("pending-line-color")).setBodyTextColor(l || A("pending-line-color")).setLineColor(f || A("pending-line-color")).setQuantityBorderColor(b || A("pending-line-color")).setCancelButtonBorderColor(y || A("pending-line-color")).setCancelButtonBackgroundColor(v || A("body-background-color")).setCancelButtonIconColor(m || A("pending-line-color")),
      //     t.trailing && o.setLineStyle(2),
      //       {
      //         type: "orderLine",
      //         value: o
      //       }
      //   }
      //     , f = function(e, t) {
      //     try {
      //       "mark" === e.type ? t.removeEntity(e.value) : e.value.remove()
      //     } catch (e) {}
      //   }

      setChartDataLoaded(true)

    })
  };

  useEffect(() => {
    // Load the TradingView Chart
    if (botData && accountData) {
      getTvChart();
    }
  }, [botData?.symbol, accountData]);

  useEffect(() => {
    // Fetch new AccountData from the API
    if (botData && botData.account_id && botData.account_id.length > 0 && botData.account_id !== accountData?._id) {
      const resource = `account/${botData.account_id}`
      CBApi.fetch(resource, 'GET').then(ReturnData => {
        setAccountData(ReturnData)
      });
    }
  }, [botData?.account_id]);


  return (
    <div id="tv_chart_container" className="TVChartContainer" style={{height: ChartHeight}}/>
  );
}

export default TrainingViewChart;
