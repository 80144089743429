import React, {
  useContext,
  useState,
  useEffect,
  // Component,
  useLayoutEffect,
} from 'react'
import useFetcher from '../../../hooks/useFetcher'
import {GlobalContext} from '../../../state'
import tokenFromLS from '../../../utils/tokenFromLS'
import {useFetchAccount} from '../../../hooks/useFetchAccount'
import Fieldset from '../../EditFields/Fieldset/Fieldset'
import InputField from '../../EditFields/Input/InputField'
import Select from '../../EditFields/Select/Select'
import {getSymbolBase, getSymbolQuote} from '../../../utils/symbol'
import {
  buildAccountOptions,
  getExchangeFromAccount,
} from '../../../hooks/useFetchAccount'
// import {AdvancedChart} from 'react-tradingview-embed'
// import { TradingViewEmbed } from "react-tradingview-embed";
// import {isObject} from '@amcharts/amcharts5/.internal/core/util/Type'
import tokenfromLS from '../../../utils/tokenFromLS'
import {useNavigate, useParams} from 'react-router-dom'
import * as am5 from '@amcharts/amcharts5'
import am5themes_Animated from '@amcharts/amcharts5/themes/Animated'
import * as am5xy from '@amcharts/amcharts5/xy'
import {sanitizeGridbotData} from '../../../utils/sanatizer'
import Modal, {useModal} from '../../Modal/Modal'
import Datafeed from "../../../utils/datafeed/datafeed";
import {widget} from "charting_library";
import {isNaN, isObject} from "@amcharts/amcharts5/.internal/core/util/Type";
import CBApi from "../../../utils/datafeed/CBApi";
import TrainingViewChart from "../../Widget/tradingview";

const GridbotDetailPage = () => {
  const globalState = useContext(GlobalContext)
  const {openModal} = useModal();
  let params = useParams();
  let id = params.id
  // const [botData, setBotData] = useFetchGridbot(id)
  const [botData, setBotData] = useFetcher(
    `gridbot/${id}`,
    'GET',
    sanitizeGridbotData
  )

  const [addFunds, setAddFunds] = useState([])

  const [formSubmitMsg, setformSubmitMsg] = useState(null)
  // const [LogData, setLogData] = useState([])
  const [showModalAddFunds, setShowModalAddFunds] = useState(false)
  const [Futures, setFutures] = useState(false)

  const navigate = useNavigate()

  // const [botData, setBotData] = useFetcher(
  //   `gridbot/${id}`,
  //   'GET',
  //   sanitizeGridbotData
  // )

  const [accountData] = useFetchAccount()
  // const [accountData, setAccountData] = useFetcher(
  //   `account/${botData?.account_id}/market_pairs`,
  //   'GET',
  // )

  //(const [symbolData] = useFetchSymbols(botData.account_id)
  const [symbols, setSymbols] = useState([])

  const [lastAccountIdForSymbols, setLastAccountIdForSymbols] = useState(null)
  const [lastAccountIdSymbols, setLastAccountIdSymbols] = useState(null)
  function getSymbols(accountId) {
    const apiHost = process.env.REACT_APP_API_URI
    const resource = `${apiHost}/account/${accountId}/market_pairs`
    const fetchOptions = {
      headers: {
        Authorization: `Bearer ${tokenFromLS(globalState.token)}`,
      },
    }
    const fetchData = async () => {
      try {
        const response = await fetch(resource, fetchOptions)
        return await response.json()
      } catch (error) {
        console.log('error', error)
      }
    }
    if (lastAccountIdForSymbols !== accountId) {
      setLastAccountIdForSymbols(accountId)
      const fetchedData = fetchData()
      setLastAccountIdSymbols(fetchedData)
      return fetchedData
    } else {
      return lastAccountIdSymbols
    }
  }

  // profitcomplete
  const [ProfitComplete, setProfitComplete] = useState([])

  function sendOrder(type, id) {
    const botId = botData._id
    console.log('sendOrder', type, id, botId)
    console.log('botData', botData)
    const apiHost = process.env.REACT_APP_API_URI
    const resource = `${apiHost}/gridbot/${botId}/order/${type}/${id}`
    const fetchOptions = {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${tokenFromLS(globalState.token)}`,
      },
    }
    const fetchData = async () => {
      try {
        const response = await fetch(resource, fetchOptions)
        return await response.json()
      } catch (error) {
        console.log('error', error)
      }
    }
    return fetchData()

  }

  // Set default Account Id, if not set
  if (botData && botData.account_id === '') {
    let Accounts = buildAccountOptions(
      accountData,
      botData.type?.toLowerCase().search('spot') ? ['KuCoinFuture'] : ['BinanceSpot', 'BybitSpot'],
      '',
      true
    )
    if (Accounts.length > 0) {
      botData.account_id = Accounts[0].value
      // botData.exchange = Accounts[0].exchange
      // Load Symbols
      getSymbols(botData.account_id).then((data) => setSymbols(data))
    }
  }

  function buildAccountOptionsWithLoadSymbols(arr, api = '', noapi = '', type = 'api') {
    const returnData = buildAccountOptions(arr, api, noapi, type)
    // if botData.account_id not exists in returnData, fetch symbols
    if (returnData && returnData.length > 0) {
      if (returnData.findIndex((item) => item.value === botData.account_id) === -1) {
        botData.account_id = returnData[0].value
        getSymbols(botData.account_id).then((data) => {
          setSymbols(data);
          if (data.length > 0 && !botData.symbol) {
            setBotData((prevState) => ({
              ...prevState,
              symbol: data[0].symbol,
            }));
          }
        });
      }
    }

    return returnData
  }

  // Futures Check
  function FuturesCheck() {
    // console.log('FuturesCheck', botData?.account_id, botData?.symbol)
    if (botData && botData.account_id && botData.symbol !== '') {
      // Check, if symbol has ":" in it
      let Symbol = botData.symbol
      if (Symbol.indexOf(':') !== -1) {
        setFutures(true)
      } else {
        setFutures(false)
      }
    }
  }

  useEffect(() => {
    FuturesCheck()
  }, [botData?.symbol, symbols]);


  function getProfitComplete(id) {
    const apiHost = process.env.REACT_APP_API_URI
    const resource = `${apiHost}/gridbot/${id}/profitcomplete`
    const fetchOptions = {
      headers: {
        Authorization: `Bearer ${tokenFromLS(globalState.token)}`,
      },
    }
    const fetchData = async () => {
      try {
        const response = await fetch(resource, fetchOptions)
        return await response.json()
      } catch (error) {
        console.log('error', error)
      }
    }
    return fetchData()
  }

  useEffect(() => {
    if (botData && botData.account_id) {
      getSymbols(botData.account_id).then((data) => setSymbols(data))
    }
  }, [botData])

  useEffect(() => {
    if (botData && botData._id) {
      getProfitComplete(botData._id).then((data) => setProfitComplete(data))
    }
  }, [botData?._id])

  useEffect(() => {
    // console.log('useEffect', accountData)
    // Special: For add a bot:
    if (
      botData &&
      !botData.account_id &&
      accountData &&
      accountData.length > 0
    ) {
      // console.log('useEffect accountdata: ', accountData)
      setBotData({...botData, account_id: accountData[0]._id})
    }
  }, [accountData])

  // useEffect(() => {
  //   // Old buy and sell lines:
  //   const Window = window
  //   const tvWidget = Window.tvWidget
  //   if (!tvWidget) {
  //     return
  //   }
  //   if (!activeChart) {
  //     return
  //   }
  //   if (!lastTrades) {
  //     return
  //   }
  //   // console.log(activeChart);
  //   // console.log(lastTrades);
  //
  //   if (lastTrades?.status === 'ok') {
  //     // console.log('lastTrades', lastTrades.data)
  //     const Strategy = botData.strategy ?? 'long';
  //
  //     const BuyColor = Strategy === 'long' ? '#4181BE' : '#00A59A';
  //     const SellColor = Strategy === 'long' ? '#00A59A' : '#4181BE';
  //
  //     // draw all trades:
  //     lastTrades.data.forEach((trade) => {
  //       // console.log('time', new Date(trade.exchange_lasttradedate).getTime() )
  //       const CurrentTimeStamp = new Date(trade.exchange_lasttradedate).getTime() / 1000 - (new Date().getTimezoneOffset() * 60);
  //
  //       // check activeChart timestamp and trade timestamp
  //       // tvWidget.activeChart().getVisibleRange().
  //
  //       // Check CurrentTimeStamp
  //       if (!isNaN(CurrentTimeStamp) && activeChart && activeChart.getVisibleRange().from <= CurrentTimeStamp && activeChart.getVisibleRange().to >= CurrentTimeStamp) {
  //
  //         // Check, if the shape already exist:
  //         if (trade.ShapeId) {
  //           if (activeChart.getShapeById(trade.ShapeId)) {
  //             return
  //           }
  //         }
  //
  //         if (trade.side === 'buy') {
  //           // Icon:
  //           trade.ShapeId = activeChart.createShape({
  //             time: CurrentTimeStamp,
  //             price: trade.exchange_average,
  //           }, {
  //             // shape: 'arrow_up',
  //             shape: 'icon',
  //             icon: 62299, // 62299 - f35b - arrow_up
  //             lock: true,
  //             showInObjectsTree: false,
  //             disableSelection: true,
  //             zOrder: "top",
  //             // Hex Number:
  //             overrides: {
  //               color: BuyColor,
  //               size: 18,
  //
  //               text: (trade.name ?? ''),
  //
  //             }
  //           })
  //           //
  //           // if (EntityId) {
  //           //   console.log('EntityId', EntityId)
  //           // }
  //           // Text:
  //           activeChart.createShape({
  //             time: CurrentTimeStamp,
  //             price: trade.exchange_average,
  //           }, {
  //             shape: 'note',
  //             text: (trade.name ?? '') + ' Id: ' + trade.id + '\nAverage: ' + trade.exchange_average + ' Filled: ' + trade.exchange_filled,
  //             lock: true,
  //             showInObjectsTree: false,
  //             // disableSelection: true,
  //             zOrder: "top",
  //             overrides: {
  //               markerColor: 'rgba(0,0,0,0)', // Transparent
  //               backgroundColor: BuyColor,
  //               backgroundTransparency: 30,
  //               borderColor: '#000000',
  //               transparency: 0,
  //             }
  //           })
  //
  //         }
  //         if (trade.side === 'sell') {
  //           // Icon:
  //           trade.ShapeId = activeChart.createShape({
  //             time: CurrentTimeStamp,
  //             price: trade.exchange_average,
  //           }, {
  //             // shape: 'arrow_down',
  //             shape: 'icon',
  //             icon: 62296, // 62296 - f358 - arrow_down
  //             lock: true,
  //             showInObjectsTree: false,
  //             zOrder: "top",
  //             overrides: {
  //               color: SellColor,
  //               size: 18,
  //               text: trade.name ?? '',
  //             }
  //           })
  //           // Text:
  //           activeChart.createShape({
  //             time: CurrentTimeStamp,
  //             price: trade.exchange_average,
  //           }, {
  //             shape: 'note',
  //             text: (trade.name ?? '') + ' Id: ' + trade.id + '\nAverage: ' + trade.exchange_average + ' Filled: ' + trade.exchange_filled,
  //             lock: true,
  //             showInObjectsTree: false,
  //             // disableSelection: true,
  //             zOrder: "top",
  //             overrides: {
  //               markerColor: 'rgba(0,0,0,0)', // Transparent
  //               backgroundColor: SellColor,
  //               backgroundTransparency: 30,
  //               borderColor: '#000000',
  //               transparency: 0,
  //             }
  //           })
  //         }
  //       }
  //     })
  //   }
  //   // CurrentChart.createShape({
  //   //   time: new Date().getTime(),
  //   //   price: 0.4967,
  //   // }, {
  //   //   shape: 'arrow_down',
  //   //   overrides: {
  //   //     size: 5,
  //   //     color: '#ff0000',
  //   //   }
  //   // })
  //
  //   //}, [lastTrades, window?.tvWidget])
  // }, [lastTrades, activeChart, changeRangeInTV])


  function mapSymbols(arr) {
    if (arr && arr.length) {
      return arr.map((item) => ({name: item.symbol, value: item.symbol}))
    }
    return []
  }

  function onChangeFirstInput(evt) {
    setBotData(function (prevState) {
      return {
        ...prevState,
        name: evt.target.value,
      }
    })
  }

  function getLimitsFromSymbol(aSymbol) {
    let ReturnSymbol = {}
    if (symbols.length) {
      symbols.forEach((item) => {
        if (item.symbol === aSymbol) {
          ReturnSymbol = item
        }
      })
    }
    return ReturnSymbol
  }

  function getPrecisionFromSymbol(aSymbol) {
    let ReturnSymbol = {
      base: 8,
      quote: 8,
      amount: 0,
      price: 0,
    }
    if (
      symbols &&
      symbols.length &&
      symbols.length > 0 &&
      aSymbol &&
      aSymbol !== ''
    ) {
      symbols.forEach((item) => {
        if (item.symbol === aSymbol && item.precision) {
          ReturnSymbol.base = item.precision.base
            ? item.precision.base
            : ReturnSymbol.base
          ReturnSymbol.quote = item.precision.quote
            ? item.precision.quote
            : ReturnSymbol.quote
          ReturnSymbol.amount = item.precision.amount
            ? item.precision.amount
            : ReturnSymbol.amount
          ReturnSymbol.price = item.precision.price
            ? item.precision.price
            : ReturnSymbol.price
        }
      })
    }
    // console.log('getPrecisionFromSymbol', ReturnSymbol)
    return ReturnSymbol
  }

  function getLimitsFromSymbol(aSymbol) {
    let ReturnSymbol = {
      amount: {
        min: null,
        max: null,
      },
      cost: {
        min: null,
        max: null,
      },
      leverage: {
        min: null,
        max: null,
      },
      market: {
        min: null,
        max: null,
      },
      price: {
        min: null,
        max: null,
      },
    }
    if (symbols.length) {
      symbols.forEach((item) => {
        if (item.symbol === aSymbol && item.limits) {
          ReturnSymbol.amount.min =
            item.limits && item.limits.amount && item.limits.amount.min
              ? item.limits.amount.min
              : null
          ReturnSymbol.amount.max =
            item.limits && item.limits.amount && item.limits.amount.max
              ? item.limits.amount.max
              : null
          ReturnSymbol.cost.min =
            item.limits && item.limits.cost && item.limits.cost.min
              ? item.limits.cost.min
              : null
          ReturnSymbol.cost.max =
            item.limits && item.limits.cost && item.limits.cost.max
              ? item.limits.cost.max
              : null
          ReturnSymbol.leverage.min =
            item.limits && item.limits.leverage && item.limits.leverage.min
              ? item.limits.leverage.min
              : null
          ReturnSymbol.leverage.max =
            item.limits && item.limits.leverage && item.limits.leverage.max
              ? item.limits.leverage.max
              : null
          ReturnSymbol.market.min =
            item.limits && item.limits.market && item.limits.market.min
              ? item.limits.market.min
              : null
          ReturnSymbol.market.max =
            item.limits && item.limits.market && item.limits.market.max
              ? item.limits.market.max
              : null
          ReturnSymbol.price.min =
            item.limits && item.limits.price && item.limits.price.min
              ? item.limits.price.min
              : null
          ReturnSymbol.price.max =
            item.limits && item.limits.price && item.limits.price.max
              ? item.limits.price.max
              : null
        }
      })
    }
    // console.log('getLimitsFromSymbol', ReturnSymbol)
    return ReturnSymbol
  }

  const onSubmitForm = async (evt) => {
    evt.preventDefault()
    setformSubmitMsg(null)
    const formData = new FormData(evt.target)

    let data = Object.fromEntries(formData)
    const {diff_price_min, diff_price_max, diff_value, diff_buy_type} = data
    delete data.diff_price_min
    delete data.diff_price_max
    delete data.diff_value
    delete data.diff_buy_type
    // // delete data.id
    data = {
      ...data,
      diff: {
        diffbuy: {
          type: diff_buy_type,
        },
        diffsell: {
          type: diff_buy_type,
        },
        price_min: diff_price_min,
        price_max: diff_price_max,
        value: diff_value,
      },
      gridentry: diff_price_max,
    }
    // console.log('data', data)
    // console.log('botData', botData)
    //   api: api,
    //   secret: {
    //     secretKey,
    //     apiKey,
    //     secretToken,
    //   }
    // }

    try {
      let RequestURL = `${process.env.REACT_APP_API_URI}/gridbot/${id}`
      let Method = 'PUT'
      if (id === 'add') {
        RequestURL = `${process.env.REACT_APP_API_URI}/gridbot`
        Method = 'ADD'
        delete data._id
      }
      const response = await fetch(RequestURL, {
        method: Method,
        body: JSON.stringify(data),
        headers: {
          Authorization: `Bearer ${tokenfromLS(globalState.token)}`,
          Accept: 'application/json',
          'Content-Type': 'application/json; charset=utf-8',
        },
      })

      const json = await response.json()
      if (json.status === 'ok') {
        navigate('/gridbot')
      } else {
        openModal({
          content: 'Error: ' + (json.error || ' ') + (JSON.stringify(json?.data) || ' ') + (JSON.stringify(json?.error_description) || ' ' + JSON.stringify(json?.message) || ' '),
          showDismissButton: true,
          dismissButtonTxt: 'Close',
          showFooter: true,
        });
      }
    } catch (err) {
      openModal({
        content: 'Error: ' + err,
        showDismissButton: true,
        dismissButtonTxt: 'Close',
        showFooter: true,
      });
    }
  }

  useLayoutEffect(() => {
    // if HTML Element chartdiv_stats_trade exists
    if (!document.getElementById('chart_profit_USD')) {
      return
    }

    let root_stats_trades = am5.Root.new('chart_profit_USD')

    // Set themes
    // https://www.amcharts.com/docs/v5/concepts/themes/
    root_stats_trades.setThemes([am5themes_Animated.new(root_stats_trades)])

    // Create chart
    // https://www.amcharts.com/docs/v5/charts/xy-chart/
    let chart = root_stats_trades.container.children.push(
      am5xy.XYChart.new(root_stats_trades, {
        panX: true,
        panY: true,
        wheelX: 'panX',
        wheelY: 'zoomX',
        pinchZoomX: true,
      })
    )

    // Add cursor
    // https://www.amcharts.com/docs/v5/charts/xy-chart/cursor/
    let cursor = chart.set(
      'cursor',
      am5xy.XYCursor.new(root_stats_trades, {
        behavior: 'zoomX',
      })
    )
    // let cursor = chart.set("cursor", am5xy.XYCursor.new(root_stats_trades, {}));
    cursor.lineY.set('visible', false)

    let date = new Date()
    date.setHours(0, 0, 0, 0)
    let value = 100

    function generateData() {
      value = Math.round(Math.random() * 10 - 5 + value)
      am5.time.add(date, 'day', 1)
      return {
        date: date.getTime(),
        value: value,
      }
    }

    function generateDatas(count) {
      let data = []
      for (var i = 0; i < count; ++i) {
        data.push(generateData())
      }
      return data
    }

    // Create axes
    // https://www.amcharts.com/docs/v5/charts/xy-chart/axes/
    let xAxis = chart.xAxes.push(
      am5xy.DateAxis.new(root_stats_trades, {
        // maxDeviation: 0.3,
        baseInterval: {
          timeUnit: 'day',
          count: 1,
        },
        renderer: am5xy.AxisRendererX.new(root_stats_trades, {}),
        tooltip: am5.Tooltip.new(root_stats_trades, {}),
      })
    )

    let yAxis = chart.yAxes.push(
      am5xy.ValueAxis.new(root_stats_trades, {
        renderer: am5xy.AxisRendererY.new(root_stats_trades, {}),
        // tooltip: am5.Tooltip.new(root_stats_trades, {})
      })
    )

    // Add series
    // https://www.amcharts.com/docs/v5/charts/xy-chart/series/
    let series = chart.series.push(
      am5xy.ColumnSeries.new(root_stats_trades, {
        name: 'profit_USD',
        xAxis: xAxis,
        yAxis: yAxis,
        valueYField: 'profit_USD',
        valueXField: 'date',
        tooltip: am5.Tooltip.new(root_stats_trades, {
          labelText: '{valueY}',
        }),
      })
    )

    // Chart title
    let title = chart.plotContainer.children.push(
      am5.Label.new(root_stats_trades, {
        text: 'Daily Profit in $',
        fontSize: 20,
        fontWeight: '400',
        x: am5.p50,
        centerX: am5.p50,
      })
    )

    let data = []
    if (ProfitComplete && ProfitComplete.stats_daily) {
      for (let i = 0; i < ProfitComplete.stats_daily.length; i++) {
        let dataentry = {
          date: new Date(ProfitComplete.stats_daily[i]._id).getTime(),
          profit: parseFloat(ProfitComplete.stats_daily[i].profit),
          profit_USD: parseFloat(ProfitComplete.stats_daily[i].profit_USD),
          profit_percent: parseFloat(ProfitComplete.stats_daily[i].profit_percent),
          count: parseFloat(ProfitComplete.stats_daily[i].count),
          fee_calcUSD: parseFloat(ProfitComplete.stats_daily[i].fee_calcUSD),
        }
        data.push(dataentry)
      }
    }

    series.data.setAll(data)

    // Make stuff animate on load
    // https://www.amcharts.com/docs/v5/concepts/animations/
    series.appear(1000)
    chart.appear(1000, 100)

    return () => {
      root_stats_trades.dispose()
    }
  }, [ProfitComplete])

  useLayoutEffect(() => {
    // if HTML Element chartdiv_stats_trade exists
    if (!document.getElementById('chart_profit_stacked')) {
      return
    }

    let root_profit_stacked = am5.Root.new('chart_profit_stacked')

    // Set themes
    // https://www.amcharts.com/docs/v5/concepts/themes/
    root_profit_stacked.setThemes([am5themes_Animated.new(root_profit_stacked)])

    // Create chart
    // https://www.amcharts.com/docs/v5/charts/xy-chart/
    let chart = root_profit_stacked.container.children.push(
      am5xy.XYChart.new(root_profit_stacked, {
        panX: true,
        panY: true,
        wheelX: 'panX',
        wheelY: 'zoomX',
        pinchZoomX: true,
      })
    )

    // Add cursor
    // https://www.amcharts.com/docs/v5/charts/xy-chart/cursor/
    let cursor = chart.set(
      'cursor',
      am5xy.XYCursor.new(root_profit_stacked, {
        behavior: 'zoomX',
      })
    )
    // let cursor = chart.set("cursor", am5xy.XYCursor.new(root_profit_stacked, {}));
    cursor.lineY.set('visible', false)

    let date = new Date()
    date.setHours(0, 0, 0, 0)
    let value = 100

    // Create axes
    // https://www.amcharts.com/docs/v5/charts/xy-chart/axes/
    let xAxis = chart.xAxes.push(
      am5xy.DateAxis.new(root_profit_stacked, {
        // maxDeviation: 0.3,
        baseInterval: {
          timeUnit: 'day',
          count: 1,
        },
        renderer: am5xy.AxisRendererX.new(root_profit_stacked, {}),
        tooltip: am5.Tooltip.new(root_profit_stacked, {}),
      })
    )

    let yAxis = chart.yAxes.push(
      am5xy.ValueAxis.new(root_profit_stacked, {
        renderer: am5xy.AxisRendererY.new(root_profit_stacked, {}),
        // tooltip: am5.Tooltip.new(root_stats_trades, {})
      })
    )

    // Add series
    // https://www.amcharts.com/docs/v5/charts/xy-chart/series/
    let series = chart.series.push(
      am5xy.LineSeries.new(root_profit_stacked, {
        connect: true,
        xAxis: xAxis,
        yAxis: yAxis,
        valueYField: 'profit_USD',
        valueXField: 'date',
        tooltip: am5.Tooltip.new(root_profit_stacked, {
          labelText: '{valueY}',
        }),
      })
    )

    series.fills.template.setAll({
      fillOpacity: 0.2,
      visible: true,
    })

    series.strokes.template.setAll({
      strokeWidth: 2,
    })

    // Chart title
    let title = chart.plotContainer.children.push(
      am5.Label.new(root_profit_stacked, {
        text: 'Accumulative Profit in $',
        fontSize: 20,
        fontWeight: '400',
        x: am5.p50,
        centerX: am5.p50,
      })
    )

    let data = []
    if (ProfitComplete && ProfitComplete.stats_daily) {
      let lastUSD = 0
      for (let i = ProfitComplete.stats_daily.length - 1; i >= 0; i--) {
        let dataentry = {
          date: new Date(ProfitComplete.stats_daily[i]._id).getTime(),
          profit: parseFloat(ProfitComplete.stats_daily[i].profit),
          profit_USD: parseFloat(ProfitComplete.stats_daily[i].profit_USD) + lastUSD,
          profit_percent: parseFloat(ProfitComplete.stats_daily[i].profit_percent),
          count: parseFloat(ProfitComplete.stats_daily[i].count),
          fee_calcUSD: parseFloat(ProfitComplete.stats_daily[i].fee_calcUSD),
        }
        lastUSD = lastUSD + parseFloat(ProfitComplete.stats_daily[i].profit_USD)
        data.push(dataentry)
      }
    }

    series.data.setAll(data)

    // Make stuff animate on load
    // https://www.amcharts.com/docs/v5/concepts/animations/
    series.appear(1000)
    chart.appear(1000, 100)

    return () => {
      root_profit_stacked.dispose()
    }
  }, [ProfitComplete])

  useLayoutEffect(() => {
    // if HTML Element chartdiv_stats_trade exists
    if (!document.getElementById('chart_profit_percent')) {
      return
    }

    let root_profit_percent = am5.Root.new('chart_profit_percent')

    // Set themes
    // https://www.amcharts.com/docs/v5/concepts/themes/
    root_profit_percent.setThemes([am5themes_Animated.new(root_profit_percent)])

    // Create chart
    // https://www.amcharts.com/docs/v5/charts/xy-chart/
    let chart = root_profit_percent.container.children.push(
      am5xy.XYChart.new(root_profit_percent, {
        panX: true,
        panY: true,
        wheelX: 'panX',
        wheelY: 'zoomX',
        pinchZoomX: true,
      })
    )

    // Add cursor
    // https://www.amcharts.com/docs/v5/charts/xy-chart/cursor/
    let cursor = chart.set(
      'cursor',
      am5xy.XYCursor.new(root_profit_percent, {
        behavior: 'zoomX',
      })
    )
    // let cursor = chart.set("cursor", am5xy.XYCursor.new(root_profit_percent, {}));
    cursor.lineY.set('visible', false)

    let date = new Date()
    date.setHours(0, 0, 0, 0)
    let value = 100

    // Create axes
    // https://www.amcharts.com/docs/v5/charts/xy-chart/axes/
    let xAxis = chart.xAxes.push(
      am5xy.DateAxis.new(root_profit_percent, {
        // maxDeviation: 0.3,
        baseInterval: {
          timeUnit: 'day',
          count: 1,
        },
        renderer: am5xy.AxisRendererX.new(root_profit_percent, {}),
        tooltip: am5.Tooltip.new(root_profit_percent, {}),
      })
    )

    let yAxis = chart.yAxes.push(
      am5xy.ValueAxis.new(root_profit_percent, {
        renderer: am5xy.AxisRendererY.new(root_profit_percent, {}),
        // tooltip: am5.Tooltip.new(root_stats_trades, {})
      })
    )

    // Add series
    // https://www.amcharts.com/docs/v5/charts/xy-chart/series/
    let series = chart.series.push(
      am5xy.LineSeries.new(root_profit_percent, {
        connect: true,
        xAxis: xAxis,
        yAxis: yAxis,
        valueYField: 'profit_percent',
        valueXField: 'date',
        tooltip: am5.Tooltip.new(root_profit_percent, {
          labelText: '{valueY}',
        }),
      })
    )

    series.fills.template.setAll({
      fillOpacity: 0.2,
      visible: true,
    })

    series.strokes.template.setAll({
      strokeWidth: 2,
    })

    // Chart title
    let title = chart.plotContainer.children.push(
      am5.Label.new(root_profit_percent, {
        text: 'Profit in Percent',
        fontSize: 20,
        fontWeight: '400',
        x: am5.p50,
        centerX: am5.p50,
      })
    )

    let data = []
    if (ProfitComplete && ProfitComplete.stats_daily) {
      for (let i = 0; i < ProfitComplete.stats_daily.length; i++) {
        let dataentry = {
          date: new Date(ProfitComplete.stats_daily[i]._id).getTime(),
          profit: parseFloat(ProfitComplete.stats_daily[i].profit),
          profit_USD: parseFloat(ProfitComplete.stats_daily[i].profit_USD),
          profit_percent: parseFloat(ProfitComplete.stats_daily[i].profit_percent),
          count: parseFloat(ProfitComplete.stats_daily[i].count),
          fee_calcUSD: parseFloat(ProfitComplete.stats_daily[i].fee_calcUSD),
        }
        data.push(dataentry)
      }
    }

    series.data.setAll(data)

    // Make stuff animate on load
    // https://www.amcharts.com/docs/v5/concepts/animations/
    series.appear(1000)
    chart.appear(1000, 100)

    return () => {
      root_profit_percent.dispose()
    }
  }, [ProfitComplete])

  const Precision = getPrecisionFromSymbol(botData ? botData.symbol : '')

  // const LogDiv = (
  //   var es = new EventSource('log/' + getParameterByName('channel'));
  //   es.addEventListener("message", function (event) {
  //   var div = document.createElement("div");
  //   div.appendChild(document.createTextNode(event.data));
  //   document.body.appendChild(div);
  // });
  // </script>
  // )

  // Function to count the grid lines:
  function countGridLines() {
    const MaxStepsCalculation = 10000
    let count = 0
    const LowerGrid = parseFloat(botData.diff.price_min)
    const UpperGrid = parseFloat(botData.diff.price_max)
    const GridStep = parseFloat(botData.diff.value)
    const GridStepType =
      botData.diff && botData.diff.diffbuy && botData.diff.diffbuy.type
        ? botData.diff.diffbuy.type
        : 'static'

    if (GridStepType === 'static') {
      count = Math.round((UpperGrid - LowerGrid) / GridStep)
      // Max: MaxStepsCalculation
      if (count > MaxStepsCalculation) {
        count = MaxStepsCalculation
      }
    } else if (GridStepType === 'percent') {
      // count = Math.round((UpperGrid - LowerGrid) / (LowerGrid * GridStep / 100))
      // Percent every step:
      let CurrentStep = LowerGrid
      while (CurrentStep < UpperGrid) {
        count++
        CurrentStep =
          parseFloat(CurrentStep) +
          (parseFloat(CurrentStep) * parseFloat(GridStep)) / 100
        if (count >= MaxStepsCalculation) {
          break
        }
      }
    }
    // Min: 0
    if (count < 0) {
      count = 0
    }
    return count
  }

  function getCurrentPrice() {
    if (botData && botData.store && botData.store.last) {
      return parseFloat(botData.store.last)
    } else if (botData && botData.lastfilledorderprice) {
      return parseFloat(botData.lastfilledorderprice)
    } else if (botData && botData.diff && botData.diff.price_min) {
      return parseFloat(botData.diff.price_min)
    }
    return 0.0
  }

  function downloadCSV() {
    // Fetch data
    fetch(`${process.env.REACT_APP_API_URI}/gridbot/${id}/download/csv`, {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${tokenfromLS(globalState.token)}`,
        Accept: 'application/json',
        'Content-Type': 'application/json; charset=utf-8',
      },
    })
      .then((response) => response.blob())
      .then((blob) => {
        // Create blob link to download
        const url = window.URL.createObjectURL(
          new Blob([blob]),
        )
        const link = document.createElement('a')
        link.href = url
        link.setAttribute(
          'download',
          `crazybot_${id}.csv`,
        )

        // Append to html link element page
        document.body.appendChild(link)

        // Start download
        link.click()

        // Clean up and remove the link
        link.parentNode.removeChild(link)
      })
  }


  function minDiff() {
    const LowerGrid = parseFloat(botData.diff.price_min)
    const UpperGrid = parseFloat(botData.diff.price_max)
    const GridType = botData.type
    const GridStep = parseFloat(botData.diff.value)
    const GridStepType =
      botData.diff && botData.diff.diffbuy && botData.diff.diffbuy.type
        ? botData.diff.diffbuy.type
        : 'static'
    let minDiff = -1
    if (GridStepType === 'static') {
      minDiff = GridStep
    } else if (GridStepType === 'percent') {
      if (GridType === 'SpotInfinity') {
        // Get current price
        const CurrentPrice = parseFloat(getCurrentPrice())
        // Get Current amount_quote
        // const CurrentAmountQuote = parseFloat(botData.amount_quote)
        // Base amount
        // const BaseAmount = CurrentAmountQuote / CurrentPrice
        //
        const limits = getLimitsFromSymbol(botData.symbol)
        // console.log('limits', limits)

        let NewAmountQuote = 0
        // Amount min check
        if (limits.amount && limits.amount.min && limits.amount.min > 0) {
          NewAmountQuote =
            parseFloat(limits.amount.min) * parseFloat(CurrentPrice) +
            parseFloat(botData.amount_quote)
          minDiff =
            (100.0 / parseFloat(botData.amount_quote)) *
            parseFloat(NewAmountQuote) -
            100
        }

        //  market['limits']['cost']['min'] check
        if (limits.cost && limits.cost.min && limits.cost.min > 0) {
          const BaseOrderSize =
            parseFloat(limits.cost.min) / parseFloat(CurrentPrice)
          const CurrentBaseOrder =
            parseFloat(botData.amount_quote) / parseFloat(CurrentPrice) -
            parseFloat(BaseOrderSize)
          const NewPrice =
            parseFloat(botData.amount_quote) / parseFloat(CurrentBaseOrder)
          const CostMinDiff =
            (100.0 / parseFloat(CurrentPrice)) * parseFloat(NewPrice) - 100.0

          if (CostMinDiff > minDiff) {
            minDiff = CostMinDiff
          }
        }
      }
    }
    return minDiff
  }

  const onModalSubmitHandler = (evt) => {
    evt.preventDefault()
    setformSubmitMsg(null)
    const formData = new FormData(evt.target)

    let data = Object.fromEntries(formData)

    const sendData = {
      type: data.add_funds_type,
      amount: data.add_funds_amount,
      price: data.add_funds_price,
      side: data.add_funds_side,
    }

    try {
      let RequestURL = `${process.env.REACT_APP_API_URI}/gridbot/${id}/add_funds`
      let Method = 'POST'
      const response = fetch(RequestURL, {
        method: Method,
        body: JSON.stringify(sendData),
        headers: {
          Authorization: `Bearer ${tokenfromLS(globalState.token)}`,
          Accept: 'application/json',
          'Content-Type': 'application/json; charset=utf-8',
        },
      })

      // Get JSON Response
      response.then((res) => res.json()).then((json) => {
        if (json.status === 'ok') {
          setShowModalAddFunds(false)
          setformSubmitMsg(json)
        } else {
          setformSubmitMsg(json)
        }
      })
    } catch (err) {
      console.log(err)
    }

  }

  return (
    botData && (
      <div>
        {showModalAddFunds ? (
          <Modal
            dismissFn={() => setShowModalAddFunds(false)}
            // submitFn={submitModalAddFunds}
            showFooter={true}
            header="Add funds"
            formSubmit="addFundsSubmit"
            // showLoader={showModalLoader}
          >
            <form id="addFundsSubmit" onSubmit={onModalSubmitHandler}>
              <Fieldset legend="Add Funds" columns={1} dismiss={false}>
                <InputField
                  type="hidden"
                  name="id"
                  value={botData ? botData._id : 'add'}
                />
                <Select
                  name="add_funds_type"
                  label="Grid Type"
                  value={addFunds.type}
                  options={[
                    {name: 'Manual', value: 'manual'},
                  ]}
                  tooltip="There are different types:<br>1. Manual: You can add funds manually without a buy action on the exchange."
                  onChange={function (evt) {
                    const options = evt.target.options
                    const index = options.selectedIndex
                    setAddFunds((prevState) => ({
                      ...prevState,
                      type: options[index].value,
                    }))
                  }}
                />
                <InputField
                  type="text"
                  name="add_funds_amount"
                  label="Amount"
                  value={addFunds.amount}
                  pattern="numeric"
                  precision={Precision.base}
                  uom={getSymbolBase(botData.symbol)}
                  onChange={function (evt) {
                    setAddFunds(function (prevState) {
                      return {
                        ...prevState,
                        amount: evt.target.value,
                      }
                    })
                  }}
                />
                <InputField
                  type="text"
                  name="add_funds_price"
                  label="Price"
                  value={addFunds.price}
                  pattern="numeric"
                  precision={Precision.quote}
                  uom={getSymbolQuote(botData.symbol)}
                  onChange={function (evt) {
                    setAddFunds(function (prevState) {
                      return {
                        ...prevState,
                        price: evt.target.value,
                      }
                    })
                  }}
                />
                <Select
                  name="add_funds_side"
                  label="Side"
                  value={addFunds.side}
                  options={[
                    {name: 'Buy', value: 'buy'},
                    {name: 'Sell', value: 'sell'},
                  ]}
                  tooltip="Buy order or sell order"
                  onChange={function (evt) {
                    const options = evt.target.options
                    const index = options.selectedIndex
                    setAddFunds((prevState) => ({
                      ...prevState,
                      side: options[index].value,
                    }))
                  }}
                />
              </Fieldset>
            </form>
          </Modal>
        ) : null}
        <form
          style={{padding: '0 1.6rem', margin: '0 auto'}}
          onSubmit={onSubmitForm}
        >
          <Fieldset
            legend="TradingView"
            columns={1}
            dismiss={true}
            collapsed={true}
            height={800}
          >
            <div className="col-9 pl-0 graph">
              <TrainingViewChart botType="gridbot" botData={botData}/>
            </div>
            {/*<div className="col-9 pl-0 graph">*/}
            {/*  <div id="tv_chart_container" className="TVChartContainer" style={{height: '800px'}}/>*/}
            {/*</div>*/}
          </Fieldset>
          <Fieldset
            legend="Assistent"
            columns={1}
            dismiss={true}
            collapsed={true}
          >
            <div>Bot ID: {botData._id}</div>
            <div>Orders on exchange: {botData.lastsub_id}</div>
            <div>
              Last filled order side:{' '}
              {botData.lastfilledorderside ? botData.lastfilledorderside : '-'}
            </div>
            <div>
              Last filled order price:{' '}
              {parseFloat(
                botData.lastfilledorderprice
                  ? botData.lastfilledorderprice
                  : '-'
              ).toFixed(Precision.quote)}
            </div>
            <div>
              Exchange:{' '}
              {getExchangeFromAccount(accountData, botData.account_id)}
            </div>
            <div>Grid count: {countGridLines()}</div>
            <div>
              Min diff:{' '}
              {minDiff() && minDiff() >= 0 ? minDiff().toFixed(2) + '%' : '-'}
            </div>
          </Fieldset>
          <Fieldset legend="Stats" columns={1} dismiss={true} collapsed={true}>
            <div>
              Grid Profit:{' '}
              {parseFloat(
                ProfitComplete && ProfitComplete.profit
                  ? ProfitComplete.profit
                  : '0'
              ).toFixed(Precision.quote)}{' '}
              {getSymbolQuote(botData.symbol)}
            </div>
            <div>
              Grid Profit:{' '}
              {parseFloat(
                ProfitComplete && ProfitComplete.profitUSD
                  ? ProfitComplete.profitUSD
                  : '0'
              ).toFixed(8)}{' '}
              USD
            </div>
            <div>
              Grid Profit Total:{' '}
              {parseFloat(
                ProfitComplete && ProfitComplete.profit_percent
                  ? ProfitComplete.profit_percent
                  : '0'
              ).toFixed(2)}{' '}
              %
            </div>
            <div>
              Grid Profit day avg:{' '}
              {parseFloat(
                ProfitComplete && ProfitComplete.profit_percent_day
                  ? ProfitComplete.profit_percent_day
                  : '0'
              ).toFixed(2)}{' '}
              %
            </div>
            <div>
              Grid Profit last 1 day avg:{' '}
              {parseFloat(
                ProfitComplete && ProfitComplete.profit_percent_day_1
                  ? ProfitComplete.profit_percent_day_1
                  : '0'
              ).toFixed(2)}{' '}
              %
            </div>
            <div>
              Grid Profit last 7 days avg:{' '}
              {parseFloat(
                ProfitComplete && ProfitComplete.profit_percent_day_7
                  ? ProfitComplete.profit_percent_day_7
                  : '0'
              ).toFixed(2)}{' '}
              %
            </div>
            <div>
              Grid Profit last 14 days avg:{' '}
              {parseFloat(
                ProfitComplete && ProfitComplete.profit_percent_day_14
                  ? ProfitComplete.profit_percent_day_14
                  : '0'
              ).toFixed(2)}{' '}
              %
            </div>
            <div>
              Grid Profit last 30 days avg:{' '}
              {parseFloat(
                ProfitComplete && ProfitComplete.profit_percent_day_30
                  ? ProfitComplete.profit_percent_day_30
                  : '0'
              ).toFixed(2)}{' '}
              %
            </div>
            <div>
              Trade count:{' '}
              {parseFloat(
                ProfitComplete && ProfitComplete.tradecount
                  ? ProfitComplete.tradecount
                  : '0'
              ).toFixed(0)}
            </div>
            <div>
              amountlockedbase:{' '}
              {parseFloat(
                ProfitComplete && ProfitComplete.amountlockedbase
                  ? ProfitComplete.amountlockedbase
                  : '0'
              ).toFixed(Precision.base)}
            </div>
            <div>
              amountlockedquote:{' '}
              {parseFloat(
                ProfitComplete && ProfitComplete.amountlockedquote
                  ? ProfitComplete.amountlockedquote
                  : '0'
              ).toFixed(Precision.quote)}
            </div>
            <div>
              PNL:{' '}
              {parseFloat(
                ProfitComplete && ProfitComplete.pnl ? ProfitComplete.pnl : '0'
              ).toFixed(Precision.quote)}
            </div>
            <div>
              current_price_bid:{' '}
              {parseFloat(
                ProfitComplete && ProfitComplete.current_price_bid
                  ? ProfitComplete.current_price_bid
                  : '0'
              ).toFixed(Precision.quote)}
            </div>
            <div>
              current_days:{' '}
              {parseFloat(
                ProfitComplete && ProfitComplete.current_days
                  ? ProfitComplete.current_days
                  : '0'
              ).toFixed(2)}
            </div>
            <div>
              gridlines_count_total:{' '}
              {parseFloat(
                ProfitComplete && ProfitComplete.gridlines_count_total
                  ? ProfitComplete.gridlines_count_total
                  : '0'
              ).toFixed(2)}
            </div>
            <div>
              gridlines_count_sell_max:{' '}
              {parseFloat(
                ProfitComplete && ProfitComplete.gridlines_count_sell_max
                  ? ProfitComplete.gridlines_count_sell_max
                  : '0'
              ).toFixed(2)}
            </div>
            <div>
              gridlines_count_sell:{' '}
              {parseFloat(
                ProfitComplete && ProfitComplete.gridlines_count_sell
                  ? ProfitComplete.gridlines_count_sell
                  : '0'
              ).toFixed(2)}
            </div>
            <div>
              gridlines_count_buy:{' '}
              {parseFloat(
                ProfitComplete && ProfitComplete.gridlines_count_buy
                  ? ProfitComplete.gridlines_count_buy
                  : '0'
              ).toFixed(2)}
            </div>
            <div>
              gridlines_amount_quote_reserved:{' '}
              {parseFloat(
                ProfitComplete && ProfitComplete.gridlines_amount_quote_reserved
                  ? ProfitComplete.gridlines_amount_quote_reserved
                  : '0'
              ).toFixed(Precision.quote)}
            </div>
            <div>
              gridlines_amount_quote_total:{' '}
              {parseFloat(
                ProfitComplete && ProfitComplete.gridlines_amount_quote_total
                  ? ProfitComplete.gridlines_amount_quote_total
                  : '0'
              ).toFixed(Precision.quote)}
            </div>
            <div>
              gridlines_amount_quote_total_overall:{' '}
              {parseFloat(
                ProfitComplete &&
                ProfitComplete.gridlines_amount_quote_total_overall
                  ? ProfitComplete.gridlines_amount_quote_total_overall
                  : '0'
              ).toFixed(Precision.quote)}
            </div>
            <div>
              unrealized_lockedquote:{' '}
              {parseFloat(
                ProfitComplete && ProfitComplete.unrealized_lockedquote
                  ? ProfitComplete.unrealized_lockedquote
                  : '0'
              ).toFixed(Precision.quote)}
            </div>
            <div>
              unrealized_profit:{' '}
              {parseFloat(
                ProfitComplete && ProfitComplete.unrealized_profit
                  ? ProfitComplete.unrealized_profit
                  : '0'
              ).toFixed(Precision.quote)}
            </div>
            <div>
              unrealized_profitUSD:{' '}
              {parseFloat(
                ProfitComplete && ProfitComplete.unrealized_profitUSD
                  ? ProfitComplete.unrealized_profitUSD
                  : '0'
              ).toFixed(Precision.quote)}
            </div>
            <div>
              unrealized_profit_percent:{' '}
              {parseFloat(
                ProfitComplete && ProfitComplete.unrealized_profit_percent
                  ? ProfitComplete.unrealized_profit_percent
                  : '0'
              ).toFixed(2)}
            </div>
            <div>
              unrealized_profit_percent_day:{' '}
              {parseFloat(
                ProfitComplete && ProfitComplete.unrealized_profit_percent_day
                  ? ProfitComplete.unrealized_profit_percent_day
                  : '0'
              ).toFixed(2)}
            </div>
            <div>
              unrealized_profit_percent_overall:{' '}
              {parseFloat(
                ProfitComplete &&
                ProfitComplete.unrealized_profit_percent_overall
                  ? ProfitComplete.unrealized_profit_percent_overall
                  : '0'
              ).toFixed(2)}
            </div>
            <div>
              unrealized_profit_percent_overall_day:{' '}
              {parseFloat(
                ProfitComplete &&
                ProfitComplete.unrealized_profit_percent_overall_day
                  ? ProfitComplete.unrealized_profit_percent_overall_day
                  : '0'
              ).toFixed(2)}
            </div>
            <div>
              total_profit:{' '}
              {parseFloat(
                ProfitComplete && ProfitComplete.total_profit
                  ? ProfitComplete.total_profit
                  : '0'
              ).toFixed(Precision.quote)}
            </div>
            <div>
              total_profitUSD:{' '}
              {parseFloat(
                ProfitComplete && ProfitComplete.total_profitUSD
                  ? ProfitComplete.total_profitUSD
                  : '0'
              ).toFixed(Precision.quote)}
            </div>
            <div>
              total_profit_percent_locked:{' '}
              {parseFloat(
                ProfitComplete && ProfitComplete.total_profit_percent_locked
                  ? ProfitComplete.total_profit_percent_locked
                  : '0'
              ).toFixed(2)}
            </div>
            <div>
              total_profit_percent:{' '}
              {parseFloat(
                ProfitComplete && ProfitComplete.total_profit_percent
                  ? ProfitComplete.total_profit_percent
                  : '0'
              ).toFixed(2)}
            </div>
            <div>
              total_profit_percent_day:{' '}
              {parseFloat(
                ProfitComplete && ProfitComplete.total_profit_percent_day
                  ? ProfitComplete.total_profit_percent_day
                  : '0'
              ).toFixed(2)}
            </div>
            <div>
              total_profit_percent_overall:{' '}
              {parseFloat(
                ProfitComplete && ProfitComplete.total_profit_percent_overall
                  ? ProfitComplete.total_profit_percent_overall
                  : '0'
              ).toFixed(2)}
            </div>
            <div>
              total_profit_percent_overall_day:{' '}
              {parseFloat(
                ProfitComplete &&
                ProfitComplete.total_profit_percent_overall_day
                  ? ProfitComplete.total_profit_percent_overall_day
                  : '0'
              ).toFixed(2)}
            </div>
            <div
              id="chart_profit_USD"
              style={{width: '100%', height: '500px'}}
            ></div>
            <div
              id="chart_profit_stacked"
              style={{width: '100%', height: '500px'}}
            ></div>
            <div
              id="chart_profit_percent"
              style={{width: '100%', height: '500px'}}
            ></div>
          </Fieldset>
          {/*<Fieldset*/}
          {/*  legend="TradingView"*/}
          {/*  columns={1}*/}
          {/*  dismiss={true}*/}
          {/*  collapsed={true}*/}
          {/*>*/}
          {/*  <AdvancedChart*/}
          {/*    widgetProps={{*/}
          {/*      theme: 'dark',*/}
          {/*      interval: '1m',*/}
          {/*      // range: '60',*/}
          {/*      style: "1",*/}
          {/*      symbol:*/}
          {/*        getExchangeFromAccount(accountData, botData.account_id) +*/}
          {/*        ':' +*/}
          {/*        (botData.symbol*/}
          {/*          ? botData.symbol.replace('/', '').replace('-', '')*/}
          {/*          : ''),*/}
          {/*      studies: [*/}
          {/*        // "MACD@tv-basicstudies",*/}
          {/*        // "StochasticRSI@tv-basicstudies",*/}
          {/*        "RSI@tv-basicstudies",*/}
          {/*        // "TripleEMA@tv-basicstudies"*/}
          {/*      ],*/}

          {/*    }}*/}
          {/*  />*/}
          {/*</Fieldset>*/}
          <Fieldset legend="Account Settings" columns={2} dismiss={true}>
            {formSubmitMsg ? <div>{JSON.stringify(formSubmitMsg)}</div> : null}
            <InputField
              type="hidden"
              name="id"
              value={botData ? botData._id : 'add'}
            />
            <Select
              name="account_id"
              label="Account"
              options={buildAccountOptionsWithLoadSymbols(
                accountData,
                botData.type?.toLowerCase().search('spot') ? ['KuCoinFuture', 'BybitFuture'] : ['BinanceSpot', 'BybitSpot'],
                '',
                true
              )}
              value={botData.account_id}
              tooltip="Select the account to use for this bot"
              // disabled={parseFloat(ProfitComplete && ProfitComplete.tradecount ? ProfitComplete.tradecount : '0') > 0}
              onChange={function (evt) {
                const options = evt.target.options
                const index = options.selectedIndex
                setBotData((prevState) => ({
                  ...prevState,
                  account_id: options[index].value,
                }))

                // reload Symbols
                getSymbols(botData.account_id).then((data) => {
                  setSymbols(data);
                  if (data.length > 0 && !botData.symbol) {
                    setBotData((prevState) => ({
                      ...prevState,
                      symbol: data[0].symbol,
                    }));
                  }
                });

              }}
            />
            <Select
              name="type"
              label="Grid Type"
              value={botData.type}
              options={[
                {name: 'Spot Classic', value: 'SpotClassic'},
                {name: 'Spot Infinity', value: 'SpotInfinity'},
                {name: 'Spot Infinity V2', value: 'SpotInfinityV2'},
                {name: 'Perp Coinmarge', value: 'PerpRene'},
              ]}
              tooltip="Select the type of grid to use:<br>Spot Classic: Spot grid with min and max price range<br>Spot Infinity: Inital buy quantity per grid (amount_quote) and try to hold the quote amount"
              onChange={function (evt) {
                const options = evt.target.options
                const index = options.selectedIndex
                setBotData((prevState) => ({
                  ...prevState,
                  type: options[index].value,
                }))
                // reload Account data
                // call Account on change


                // if (botData && botData.account_id) {
                //   getSymbols(botData.account_id).then((data) =>
                //     setSymbols(data)
                //   )
                // }
              }}
            />
            <Select
              name="symbol"
              label="Symbol"
              value={botData.symbol}
              options={mapSymbols(symbols)}
              tooltip="Select the symbol to trade<br>ATTENTION: if the list remains empty, change the account to Dummy, then back to the desired name"
              // disabled={parseFloat(ProfitComplete && ProfitComplete.tradecount ? ProfitComplete.tradecount : "0') > 0}
              onChange={function (evt) {
                const options = evt.target.options
                const index = options.selectedIndex
                setBotData((prevState) => ({
                  ...prevState,
                  symbol: options[index].value,
                }))
              }}
            />
            <InputField
              type="text"
              name="name"
              label="Name"
              value={botData.name ? botData.name : 'Bot'}
              pattern={'alphanumericwithspace'}
              tooltip="Name of the Bot (alphanumeric with space). The name is used to identify the bot in the list."
              onChange={function (evt) {
                setBotData(function (prevState) {
                  return {
                    ...prevState,
                    name: evt.target.value,
                  }
                })
              }}
            />
            <InputField
              type="text"
              name="leverage"
              label="Leverage"
              pattern="integer"
              disabled={!Futures}
              minvalue={getLimitsFromSymbol(botData.symbol)?.leverage?.min ?? 1}
              maxvalue={getLimitsFromSymbol(botData.symbol)?.leverage?.max ?? 1}
              value={botData.leverage ? botData.leverage : '1'}
              onChange={function (evt) {
                setBotData(function (prevState) {
                  return {
                    ...prevState,
                    leverage: evt.target.value,
                  }
                })
              }}
            />
            <Select
              name="marginmode"
              label="Margin Mode"
              value={botData.marginmode}
              disabled={!Futures}
              options={[
                {name: 'Cross', value: 'cross'},
                {name: 'Isolated', value: 'isolated'},
              ]}
              onChange={function (evt) {
                const options = evt.target.options
                const index = options.selectedIndex
                setBotData((prevState) => ({
                  ...prevState,
                  marginmode: options[index].value,
                }))
              }}
            />

          </Fieldset>

          <Fieldset legend="Main Settings" columns={2} dismiss={true}>
            <InputField
              type="text"
              name="diff_price_min"
              label="Price Min"
              value={botData.diff.price_min}
              pattern="numeric"
              precision={Precision.quote}
              uom={getSymbolQuote(botData.symbol)}
              // tooltip="<h2>Hallo</h2>Lorem ipsum dolor sit amet,<br> consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet."
              tooltip="Defines the lower limit up to which the bot remains active. If it falls below, no further buy/sell orders will be placed."
              onChange={function (evt) {
                setBotData(function (prevState) {
                  return {
                    ...prevState,
                    diff: {
                      ...prevState.diff,
                      price_min: evt.target.value,
                    },
                  }
                })
              }}
            />
            <InputField
              type="text"
              name="diff_price_max"
              label="Price Max"
              value={botData.diff.price_max}
              pattern="numeric"
              precision={Precision.quote}
              uom={getSymbolQuote(botData.symbol)}
              tooltip="Defines the upper limit up to which the bot stays active. If it is exceeded, no further buy/sell orders will be placed."
              onChange={function (evt) {
                setBotData(function (prevState) {
                  return {
                    ...prevState,
                    diff: {
                      ...prevState.diff,
                      price_max: evt.target.value,
                    },
                  }
                })
              }}
            />
            <InputField
              type="text"
              name="diff_value"
              label="Grid Spacing"
              value={botData.diff.value}
              pattern="numeric"
              precision={botData.diff.uom === '%' ? 2 : Precision.quote}
              uom={botData?.diff?.diffbuy?.type === 'percent' ? '%' : botData.diff.uom}
              tooltip="Defines the spacing between grid levels. Value has to be set carefully eighter as percentage value e.g. 0.25 or as an absolute value based on asset price e.g. 250 USD. Allways to be set in combination with [Grid Type (percentage or absolute)]."
              // tooltip="Defines the spacing of the grid lines. Optimum values are between 0.23-0.35. The lower the value, the more capital-intensive the bot becomes. The value must be set so that it is suitable for the existing capital and minimum order quantity of the respective exchange."
              onChange={function (evt) {
                setBotData(function (prevState) {
                  return {
                    ...prevState,
                    diff: {
                      ...prevState.diff,
                      value: evt.target.value,
                    },
                  }
                })
              }}
            />
            <InputField
              type="text"
              name="count"
              label="Active Grid Orders"
              value={parseInt(botData.count)}
              pattern="integer"
              tooltip="Defines the number of orders on the CEX, proven default for infinity grids: 6"
              disabled={botData.type === 'PerpRene'}
              onChange={function (evt) {
                setBotData((prevState) => ({
                  ...prevState,
                  count: evt.target.value,
                }))
              }}
            />
            <InputField
              type="text"
              name="amount"
              label="Quantity per grid (amount)"
              value={botData.amount}
              pattern="numeric"
              precision={Precision.base}
              uom={botData.type === 'PerpRene' ? getSymbolQuote(botData.symbol) : getSymbolBase(botData.symbol)}
              tooltip="Set it to -1 (this value is irrelevant for the Infinity bot)"
              onChange={function (evt) {
                setBotData((prevState) => ({
                  ...prevState,
                  amount: evt.target.value,
                }))
              }}
            />
            <InputField
              type="text"
              name="amount_quote"
              label="Quantity per grid (amount_quote)"
              value={botData.amount_quote}
              pattern="numeric"
              precision={Precision.quote}
              uom={getSymbolQuote(botData.symbol)}
              tooltip="Defines the invested capital for the initial buy or the total volume in quote that the bot tries to hold during the entire runtime."
              disabled={botData.type === 'PerpRene'}
              onChange={function (evt) {
                setBotData((prevState) => ({
                  ...prevState,
                  amount_quote: evt.target.value,
                }))
              }}
            />
            <InputField
              type="text"
              name="amount_max"
              label="Quantity max allocation (amount_max)"
              value={botData.amount_max}
              pattern="numeric"
              precision={Precision.base}
              uom={getSymbolBase(botData.symbol)}
              tooltip="Set it to -1. A small emergency brake - the bot is not allowed to buy more than what is stated here. At -1 this function is disabled."
              disabled={botData.type === 'PerpRene'}
              onChange={function (evt) {
                setBotData((prevState) => ({
                  ...prevState,
                  amount_max: evt.target.value,
                }))
              }}
            />
            {((botData.type ?? '') === 'PerpRene') ? (
            <InputField
              type="text"
              name="diff.spread"
              label="Grid Spread"
              value={botData.diff.spread}
              pattern="numeric"
              precision={Precision.quote}
              uom={getSymbolQuote(botData.symbol)}
              tooltip="Defines the spread between the buy and sell orders"
              onChange={function (evt) {
                setBotData((prevState) => ({
                  ...prevState,
                  diff: {
                    ...prevState.diff,
                    spread: evt.target.value,
                  },
                }))
              }}
            />
            ) : null}
          </Fieldset>
          {((botData.type ?? '') === 'PerpRene') ? (
            <Fieldset
              legend="Perp Position Settings"
              columns={2}
              dismiss={true}
            >
              <InputField
                type="text"
                name="entry.amount"
                label="Position Creation Size"
                value={parseFloat(botData?.entry?.amount ?? '0.0')}
                pattern="numeric"
                precision={Precision.quote}
                uom={getSymbolQuote(botData.symbol)}
                tooltip="The size of the futures position bought at initial start. Default is 0.0 = disabled."
                onChange={function (evt) {
                  setBotData((prevState) => ({
                    ...prevState,
                    entry: {
                      ...prevState.entry,
                      amount: evt.target.value,
                    },
                  }))
                }}
              />
              <InputField
                type="text"
                name="amount_min_position"
                label="Minimum Position Size"
                value={parseFloat(botData?.amount_min_position ?? '0.0')}
                pattern="numeric"
                minvalue={botData?.amount ?? '0.0'}
                precision={Precision.quote}
                uom={getSymbolQuote(botData.symbol)}
                tooltip="Amount of contracts the bot tries to maintain in the open futures position, when filled sell orders reduced the position below. The bot tries to regain the set size with the next buyorder execution, as defined within the grid. Default is the start amount."
                onChange={function (evt) {
                  setBotData((prevState) => ({
                    ...prevState,
                    amount_min_position: evt.target.value,
                  }))
                }}
              />
              <InputField
                type="text"
                name="eventbo"
                label="Start Event Name"
                value={(botData?.eventbo ?? '')}
                tooltip="The bot will start with this event. Default is empty = always start."
                onChange={function (evt) {
                  setBotData((prevState) => ({
                    ...prevState,
                    eventbo: evt.target.value,
                  }))
                }}
              />
              <InputField
                type="textarea"
                name="events"
                label="Events (JSON)"
                height="300px"
                gridcolumns={2}
                // Object to String
                value={JSON.stringify(botData.events, null, 2)}
                // value={JSON.stringify(botData.process, null, 2)}
                onChange={function (evt) {
                  setBotData(function (prevState) {
                    // is it really json?
                    try {
                      return {
                        ...prevState,
                        events: JSON.parse(evt.target.value),
                      }
                    } catch (e) {
                      return {
                        ...prevState,
                        events: prevState.events,
                      }
                    }
                  })
                }}
              />

            </Fieldset>
          ) : null}
          {((botData.type ?? '') === 'SpotInfinity' || (botData.type ?? '') === 'SpotInfinityV2') ? (
            <Fieldset
              legend="Advanced Settings Infinity"
              columns={2}
              dismiss={true}
              collapsed={true}
              disabled={botData.type !== 'SpotInfinity' || botData.type !== 'PerpRene'}
            >
              <InputField
                type="switch"
                name="bo.emacross.enabled"
                label="EMA Cross Enabled"
                checked={botData.bo.emacross.enabled}
                onChange={function (evt) {
                  setBotData((prevState) => ({
                    ...prevState,
                    bo: {
                      ...prevState.bo,
                      emacross: {
                        ...prevState.bo.emacross,
                        enabled: evt.target.checked,
                      },
                    },
                  }))
                }}
              />
              <Select
                name="bo.emacross.timeframe"
                label="Timeframe"
                gridcolumns="2"
                value={botData.bo.emacross.timeframe}
                options={[
                  {name: '15s', value: '15s'},
                  {name: '1m', value: '1m'},
                  {name: '5m', value: '5m'},
                  {name: '15m', value: '15m'},
                  {name: '1h', value: '1h'},
                  {name: '4h', value: '4h'},
                  {name: '1d', value: '1d'},
                  {name: '3d', value: '3d'},
                  {name: '1w', value: '1w'},
                  {name: '2w', value: '2w'},
                  {name: '1M', value: '1M'},
                ]}
              />
              <InputField
                type="text"
                name="bo.emacross.length1"
                label="EMA Cross Length 1"
                value={parseInt(botData.bo.emacross.length1)}
                pattern="integer"
                onChange={function (evt) {
                  setBotData((prevState) => ({
                    ...prevState,
                    bo: {
                      ...prevState.bo,
                      emacross: {
                        ...prevState.bo.emacross,
                        length1: evt.target.value,
                      },
                    },
                  }))
                }}
              />
              <InputField
                type="text"
                name="bo.emacross.length2"
                label="EMA Cross Length 2"
                value={parseInt(botData.bo.emacross.length2)}
                pattern="integer"
                onChange={function (evt) {
                  setBotData((prevState) => ({
                    ...prevState,
                    bo: {
                      ...prevState.bo,
                      emacross: {
                        ...prevState.bo.emacross,
                        length2: evt.target.value,
                      },
                    },
                  }))
                }}
              />
              <InputField
                type="text"
                name="bo.emacross.amount_quote_low"
                label="EMA Cross Amount Quote Low"
                value={parseInt(botData.bo.emacross.amount_quote_low)}
                pattern="numeric"
                precision={Precision.quote}
                uom={getSymbolQuote(botData.symbol)}
                onChange={function (evt) {
                  setBotData((prevState) => ({
                    ...prevState,
                    bo: {
                      ...prevState.bo,
                      emacross: {
                        ...prevState.bo.emacross,
                        amount_quote_low: evt.target.value,
                      },
                    },
                  }))
                }}
              />
              <InputField
                type="text"
                name="bo.emacross.amount_quote_high"
                label="EMA Cross Amount Quote High"
                value={parseInt(botData.bo.emacross.amount_quote_high)}
                pattern="numeric"
                precision={Precision.quote}
                uom={getSymbolQuote(botData.symbol)}
                onChange={function (evt) {
                  setBotData((prevState) => ({
                    ...prevState,
                    bo: {
                      ...prevState.bo,
                      emacross: {
                        ...prevState.bo.emacross,
                        amount_quote_high: evt.target.value,
                      },
                    },
                  }))
                }}
              />
            </Fieldset>
          ) : null}
          <Fieldset legend="Advanced Settings" columns={2} dismiss={true}>
            <InputField
              type="text"
              name="countsell"
              label="Placed Sell Orders"
              value={botData.countsell}
              pattern="integer"
              tooltip="Defines the number of sell orders placed in the orderbook of the given exchange. Default is 6. If set to 0, the bot will not sell. This setup will slow the bot more and more, the higher the value.<br>For Infinity Bots set it to 2<br>Caution: # of orders may be limited by the exchange."
              onChange={function (evt) {
                setBotData((prevState) => ({
                  ...prevState,
                  countsell: evt.target.value,
                }))
              }}
            />
            <InputField
              type="text"
              name="countselloptional"
              label="Placed Sell Orders optional"
              value={botData.countselloptional}
              pattern="integer"
              tooltip="For Infinity Bots set it to 1. Additional active sell order lines that are not deleted by the bot if there was an active order at one time. This increases the speed of order placement."
              onChange={function (evt) {
                setBotData((prevState) => ({
                  ...prevState,
                  countselloptional: evt.target.value,
                }))
              }}
            />
            <InputField
              type="text"
              name="countbuy"
              label="Placed Buy Orders"
              value={botData.countbuy}
              pattern="integer"
              tooltip="Defines the number of buy orders placed in the orderbook of the given exchange. Default is 6. If set to 0, the bot will not buy. This setup will slow the bot more and more, the higher the value.<br>For Infinity Bots set it to 2<br>Caution: # of orders may be limited by the exchange."
              // tooltip="Defines the number of grids that are used for buying. If set to 0, the bot will not buy.<br>For Infinity Bots set it to 2. This value defines the active buy orders on the exchange."
              onChange={function (evt) {
                setBotData((prevState) => ({
                  ...prevState,
                  countbuy: evt.target.value,
                }))
              }}
            />
            <InputField
              type="text"
              name="countbuyoptional"
              label="Placed Buy Orders optional"
              value={botData.countbuyoptional}
              pattern="integer"
              tooltip="For Infinity Bots set to 1. Additional active buy order lines that are not deleted by the bot if there was an active order at one time. This increases the speed of order placement"
              onChange={function (evt) {
                setBotData((prevState) => ({
                  ...prevState,
                  countbuyoptional: evt.target.value,
                }))
              }}
            />
            <Select
              name="diff_buy_type"
              label="Grid Type (percent or absolute)"
              value={
                botData.diff &&
                botData.diff.diffbuy &&
                botData.diff.diffbuy.type
                  ? botData.diff.diffbuy.type
                  : 'static'
              }
              options={[
                {name: 'Absolute', value: 'static'},
                {name: 'Percentage', value: 'percent'},
              ]}
              tooltip="Defines the type of [Grid Spacing]. If set to percentage, the spacing of the grid will be calculated accordingly accross the price range of the asset with the given value, set with [Grid Spacing]. If set to absolute, the spacing of the grid will be fix accordingly across the pricerange of the asset and set value from [Grid Spacing]."
              onChange={function (evt) {
                const options = evt.target.options
                const index = options.selectedIndex
                setBotData((prevState) => ({
                  ...prevState,
                  diff: {
                    ...prevState.diff,
                    diffbuy: {
                      ...prevState.diff.diffbuy,
                      type: options[index].value,
                    },
                    diffsell: {
                      ...prevState.diff.diffsell,
                      type: options[index].value,
                    },
                  },
                }))
                botData.diff.uom =
                  options[index].value === 'static'
                    ? getSymbolQuote(botData.symbol)
                    : '%'
              }}
            />
            <InputField
              type="switch"
              name="rebuymissingbuyorders"
              label="Rebuy grid lines, if big drop"
              checked={botData.rebuymissingbuyorders}
              tooltip="If set to true, the bot will rebuy missing grid lines if the price drops significantly. This is useful if the bot is not running for a longer time and the price drops significantly.<br>Keep this disabled for Infinity Grids"
              disabled={botData.type === 'PerpRene'}
              onChange={function (evt) {
                setBotData((prevState) => ({
                  ...prevState,
                  rebuymissingbuyorders: evt.target.value,
                }))
              }}
            />
            <InputField
              type="switch"
              name="cycleresetafterordercomplete"
              label="React after a buy or sell is done"
              checked={botData.cycleresetafterordercomplete}
              tooltip="If set to true, the bot will reset the cycle after a buy or sell is done. Activate this setting for best performance"
              disabled={botData.type === 'PerpRene'}
              onChange={function (evt) {
                setBotData((prevState) => ({
                  ...prevState,
                  cycleresetafterordercomplete: evt.target.value,
                }))
              }}
            />
            <InputField
              type="switch"
              name="type_sellorderbook"
              label="Second mode: sell on buy orderbook only"
              checked={botData.type_sellorderbook}
              tooltip="sell on buy orderbook only. Keep this disabled for Infinity Grids"
              disabled={botData.type === 'PerpRene'}
              onChange={function (evt) {
                setBotData((prevState) => ({
                  ...prevState,
                  type_sellorderbook: evt.target.value,
                }))
              }}
            />
            <InputField
              type="switch"
              name="ignore_spread"
              label="Ignore spread"
              checked={botData.ignore_spread}
              tooltip="If set to true, the bot will ignore the spread and place orders at the current price. This is useful if the spread is too high and the bot is not able to place orders."
              disabled={botData.type === 'PerpRene'}
              onChange={function (evt) {
                setBotData((prevState) => ({
                  ...prevState,
                  ignore_spread: evt.target.value,
                }))
              }}
            />
            <InputField
              type="switch"
              name="postonly"
              label="Send only postonly orders to the exchange"
              checked={botData.postonly ? botData.postonly : true}
              tooltip="If set to true, the bot will only send postonly orders to the exchange. This is useful if you want to avoid market orders (sometimes less fees)."
              disabled={botData.type === 'PerpRene'}
              onChange={function (evt) {
                setBotData((prevState) => ({
                  ...prevState,
                  postonly: evt.target.value,
                }))
              }}
            />
            <InputField
              type="switch"
              name="dryrun"
              label="Dry run... no orders are sent"
              checked={botData.dryrun}
              tooltip="If set to true, the bot will not send any orders to the exchange. This is useful if you want to test the bot without sending orders."
              onChange={function (evt) {
                setBotData((prevState) => ({
                  ...prevState,
                  dryrun: evt.target.value,
                }))
              }}
            />
            <InputField
              type="text"
              name="cycledelay"
              label="Cycle Delay"
              value={botData.cycledelay}
              min={10}
              max={600}
              step={1}
              pattern="integer"
              tooltip="Defines the delay between two cycles in seconds. The bot will check the current price and place orders if necessary. The default value is 60 seconds."
              onChange={function (evt) {
                setBotData(function (prevState) {
                  return {
                    ...prevState,
                    cycledelay: evt.target.value,
                  }
                })
              }}
            />
          </Fieldset>

          {/*<Fieldset legend="Advanced Settings" columns={2} dismiss={true}>*/}
          {/*</Fieldset>*/}

          {/*<Fieldset legend="TEST Dismissable Fieldset" columns={2} dismiss={true}>*/}
          {/*  <InputField*/}
          {/*    type="text"*/}
          {/*    name="bot-name"*/}
          {/*    label="Bot Name"*/}
          {/*    value={botData.name}*/}
          {/*    uom={botData.symbol}*/}
          {/*    onChange={onChangeFirstInput}*/}
          {/*  />*/}
          {/*  <InputField*/}
          {/*    type="text"*/}
          {/*    name="bot-name"*/}
          {/*    label="Bot Symbol disabled"*/}
          {/*    value={botData.symbol}*/}
          {/*    uom={botData.symbol}*/}
          {/*    disabled={true}*/}
          {/*  />*/}
          {/*  <InputField*/}
          {/*    type="switch"*/}
          {/*    name="bot-switch"*/}
          {/*    label="Bot Switch checked and disabled"*/}
          {/*    checked={true}*/}
          {/*    disabled={false}*/}
          {/*  />*/}
          {/*  <InputField type="switch" name="bot-switch" label="Bot Switch"/>*/}
          {/*  <InputField*/}
          {/*    type="hidden"*/}
          {/*    name="hidden-field"*/}
          {/*    value={botData.name}*/}
          {/*  />*/}
          {/*</Fieldset>*/}

          {/*<Fieldset*/}
          {/*  legend="TEST Switchable Fieldset"*/}
          {/*  columns={2}*/}
          {/*  dismiss={true}*/}
          {/*  switchable={true}*/}
          {/*>*/}
          {/*  <InputField*/}
          {/*    type="text"*/}
          {/*    name="bot-name"*/}
          {/*    label="Bot Name"*/}
          {/*    value={botData.name}*/}
          {/*    uom={botData.symbol}*/}
          {/*  />*/}
          {/*  <Select*/}
          {/*    name="exchange-accounts"*/}
          {/*    label="Exchange Accounts"*/}
          {/*    options={buildAccountOptions(accountData)}*/}
          {/*    onChange={function (evt) {*/}
          {/*      const options = evt.target.options*/}
          {/*      const index = options.selectedIndex*/}
          {/*      getSymbols(options[index].value).then((data) =>*/}
          {/*        setSymbols(data)*/}
          {/*      )*/}
          {/*    }}*/}
          {/*  />*/}
          {/*  <Slider*/}
          {/*    name="slider-example"*/}
          {/*    label="Slider Example"*/}
          {/*    value={1}*/}
          {/*    min={0}*/}
          {/*    max={2}*/}
          {/*    step={0.1}*/}
          {/*    uom={botData.symbol}*/}
          {/*    onChange={(evt) => console.log(evt)}*/}
          {/*  />*/}
          {/*  <Select*/}
          {/*    name="symbols"*/}
          {/*    label="Symbols"*/}
          {/*    value={botData.symbol}*/}
          {/*    options={mapSymbols(symbols)}*/}
          {/*    onChange={function (evt) {*/}
          {/*      const options = evt.target.options*/}
          {/*      const index = options.selectedIndex*/}
          {/*      setBotData((prevState) => ({*/}
          {/*        ...prevState,*/}
          {/*        symbol: options[index].value,*/}
          {/*      }))*/}
          {/*    }}*/}
          {/*  />*/}
          {/*</Fieldset>*/}
          <button type="submit">Submit</button>
        </form>
        {/* External Link for the api page to open the log */}
        <button
          onClick={() =>
            window.open(
              'https://api.crazybot.io/v1/ws/log/console/gridbot-' + botData._id
            )
          }
        >
          Open Log
        </button>
        <button
          onClick={function (evt) {
            downloadCSV()
          }}
        >
          Download CSV
        </button>
        {/*<button*/}
        {/*  onClick={function (evt) {*/}
        {/*    setShowModalAddFunds(true)*/}
        {/*  }}*/}
        {/*>*/}
        {/*  Add Funds*/}
        {/*</button>*/}
      </div>
    )
  )
}

export default GridbotDetailPage
